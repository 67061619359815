import { Vector3 } from 'three';
import { CamMovingCenter } from '../CamMovingCenter';
import useAnimation from './useAnimation';

interface Position {
    x?: number;
    y?: number;
    z?: number;
}

interface Location {
    target: Position;
    duration: number;
}

// 輸入 original 和 target 座標然後就會經由 useAnimation 曲線抵達目的地
const useMakeAtoB = (location: Location, center: CamMovingCenter) => {
    const { target, duration } = location;
    const aTobFunc = (_percent: number, original?: any) => {
        const checkOriginalX = original.x;
        const checkOriginalY = original.y;
        const checkOriginalZ = original.z;

        const movementX =
            target.x !== undefined
                ? checkOriginalX + (target.x - checkOriginalX) * _percent
                : checkOriginalX;
        const movementY =
            target.y !== undefined
                ? checkOriginalY + (target.y - checkOriginalY) * _percent
                : checkOriginalY;
        const movementZ =
            target.z !== undefined
                ? checkOriginalZ + (target.z - checkOriginalZ) * _percent
                : checkOriginalZ;

        center.cameraPosition = new Vector3(movementX, movementY, movementZ);
    };

    const action = useAnimation(center, aTobFunc, duration);

    return action;
};

export default useMakeAtoB;
