import { useEffect } from 'react';
import { Html } from '@react-three/drei';
import { ReactComponent as Scroll } from '../../../assets/icons/scroll.svg';
import { useMainContext } from 'hooks/useMainContext';
import './scrollNotice.scss';

const ScrollNotice = () => {
    const { center } = useMainContext();

    const handleWheel = () => {
        const scrollNoticeEl: any = document.getElementsByClassName(
            'fixed-button-group-scroll'
        )[0];


        if (center.isMoving) return;

        center.lobyScrollPercent >= 0
            ? (scrollNoticeEl.style.opacity = 0)
            : (scrollNoticeEl.style.opacity = 1);
    };

    useEffect(() => {
        window.addEventListener('wheel', handleWheel);

        return () => {
            window.removeEventListener('wheel', handleWheel);
        };
    }, []);

    return (
        <>
            <Html
                as="div"
                wrapperClass="fixed-button-group-scroll"
                style={{
                    position: 'absolute',
                    bottom: '18%',
                    left: 'calc(50% - 4rem)'
                }}
            >
                <div className="scroll-container">
                    <div className="icon">
                        <Scroll />
                    </div>
                    <div className="text">SCROLL</div>
                </div>
            </Html>
        </>
    );
};

export default ScrollNotice;
