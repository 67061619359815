import { useMemo, memo } from 'react';
import { Plane } from '@react-three/drei';
import { useRotate } from 'hooks/useRotate';
import { FrontSide } from 'three';
import { useMainContext } from 'hooks/useMainContext';


const CloudSmoke = ({ position, scale, opacity, layers = 1 }: any) => {
    const { textures } = useMainContext();
    const { c1 } = textures;
    const smokeRef = useRotate();

    // 刻意使用useMemo
    const material = useMemo(() => (
        <meshBasicMaterial
            map={c1}
            transparent={true}
            side={FrontSide}
            opacity={opacity}
        />
    ), [c1, opacity]);
    
    return (
        <Plane
            ref={smokeRef.ref}
            args={[11.3, 10]}
            position={position}
            scale={scale}
            layers={layers}
        >
            {material}
        </Plane>
    );
};

export default memo(CloudSmoke);
