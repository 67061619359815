import { CamMovingCenter } from '../CamMovingCenter';
import useAnimation from './useAnimation';

export const useTransitions = (center: CamMovingCenter) => {
    // 增加亮度
    const handleBrightnessEject = (_percent: number) => {
        center.brightness = _percent;
    };
    // 減少亮度
    const handleBrightnessLessEject = (_percent: number) => {
        center.brightness = 1 - _percent;
    };

    const handleBrightness = useAnimation(center, handleBrightnessEject, 1);
    const handleBrightnessLess = useAnimation(center, handleBrightnessLessEject, 1);


    return { handleBrightness, handleBrightnessLess };
};