import { useFormContext } from 'react-hook-form';

const InputRadio = ({ label, list, id, required = false }: any) => {
    const {
        register,
        setValue,
    } = useFormContext();
    const { name } = register(id, { required: required });

    return (
        <div className="inputRadio">
            <div>
                {label}
            </div>
            {list.map((item: any, index: number) => {
                return (
                    <div style={{ float: 'left'}}>
                        <input
                            type="radio"
                            name={name}
                            id={`"${id}${index}"`}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                setValue(id, item.value);
                            }}
                        />
                        <label
                            htmlFor={`"${id}${index}"`}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                setValue(id, item.value);
                            }}
                        >
                            {item.name}
                        </label>
                    </div>
                );
            })}
        </div>
    );
};

export default InputRadio;
