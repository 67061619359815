import { Text } from '@react-three/drei';
import useHallwayThree from '../useHallwayThree';
import { useDetectGPU } from '@react-three/drei';
import { memo, Suspense, lazy} from 'react';
import CloudSmoke from 'components/models/CloudSmoke/CloudSmoke';
// import Award from '../../Award/Award';
// import BottomArtModelOne from 'components/models/BottomArtModelOne/BottomArtModelOne';
// import BottomArtModelThree from 'components/models/BottomArtModelThree/BottomArtModelThree';
// 3D model
const Award = lazy(() => import('../../Award/Award'));
const BottomArtModelOne = lazy(() => import('components/models/BottomArtModelOne/BottomArtModelOne'));
const BottomArtModelThree = lazy(() => import('components/models/BottomArtModelThree/BottomArtModelThree'));


const HallwayThreePc = () => {
    const { ARR3D, ARRLAST, ARREGR, ARRSTAR } = useHallwayThree();
    const { isMobile } = useDetectGPU();
    if (isMobile) return <></>;
    return (
        <>
            <Suspense>
                <BottomArtModelThree position={[0, -81, -230]} />
            </Suspense>

            {/* DEV */}
            {/* <Plane
                args={[10, 10]}
                position={[30, -81, -30]}
                layers={1}
            >
                <meshBasicMaterial
                    color={'#21e3f3'}
                    side={THREE.DoubleSide}
                />
            </Plane> */}

            <Text
                layers={1}
                characters="ENTER"
                position={[0, -80, -70]}
                color={'#4cd8c0'}
                font="/leading/leadingPageAssets/fonts/BoonTook-Regular.ttf"
                letterSpacing={-0.03}
            >
                Honors
            </Text>
            <Text
                layers={1}
                characters="ENTER"
                position={[0, -81, -70]}
                color={'#4cd8c0'}
                font="/leading/leadingPageAssets/fonts/BoonTook-Regular.ttf"
                letterSpacing={-0.03}
            >
                and Awards
            </Text>
            <Text
                layers={1}
                font="/leading/leadingPageAssets/fonts/DIN Alternate Bold OK.ttf"
                characters="ENTER"
                color={'#4cd8c0'}
                position={[0, -81.8, -70]}
                fontSize={0.2}
                letterSpacing={0.5}
            >
                2016 - 2023
            </Text>

            {/* 3d物件1 */}
            <group>
                {ARR3D.map((item, index) => {
                    return (
                       
                            <CloudSmoke
                                key={index}
                                {...item}
                            />
                        
                        
                    );
                })}
            </group>

            {/* last  */}
            <group>
                {ARRLAST.map((item, index) => {
                    return (
                            <CloudSmoke
                                key={index}
                                {...item}
                            />
                    );
                })}
            </group>

            {/* egr */}
            <group>
                {ARREGR.map((item, index) => {
                    return (
                             <CloudSmoke
                                key={index}
                                {...item}
                            />
                    );
                })}
            </group>

            {/* star */}
            <group>
                {ARRSTAR.map((item, index) => {
                    return (
                            <CloudSmoke
                                key={index}
                                {...item}
                            />
                    );
                })}
            </group>
            
            <Suspense>
                <BottomArtModelOne position={[1, -80.5, -88]} />
            </Suspense>

            <Suspense>
                <Award />
            </Suspense>
        </>
    );
};

export default memo(HallwayThreePc);
