import { useMemo } from 'react';

const useLoadTexturePath = () => {
    return useMemo(() => {
        return [
            // BtmFloor
            '/leading/leadingPageAssets/models/Bottom/Floor/BtmFloor_Albeto.ktx2',
            '/leading/leadingPageAssets/models/Bottom/Floor/BtmFloor_Normal.ktx2',
            '/leading/leadingPageAssets/models/Bottom/Floor/BtmFloor_Envi.ktx2',
            // BtmWall
            '/leading/leadingPageAssets/models/Bottom/Wall/BtmWall_Albeto.ktx2',
            '/leading/leadingPageAssets/models/Bottom/Wall/BtmWall_Normal.ktx2',
            '/leading/leadingPageAssets/models/Bottom/Wall/BtmWall_Environment.ktx2',
            // BottomArtModelOne
            '/leading/leadingPageAssets/models/Bottom/Abstract01/Abstract01_Albeto.ktx2',
            '/leading/leadingPageAssets/models/Bottom/Abstract01/Abstract01_MetalLightmap.ktx2',
            '/leading/leadingPageAssets/models/Bottom/Abstract01/Abstract01_Normal.ktx2',
            '/leading/leadingPageAssets/models/Bottom/Abstract01/Abstract01_Env.ktx2',
            // BottomArtModelThree
            '/leading/leadingPageAssets/models/Bottom/Abstract03/Abtract03_Albeto.ktx2',
            '/leading/leadingPageAssets/models/Bottom/Abstract03/Abtract03_Metalness.ktx2',
            '/leading/leadingPageAssets/models/Bottom/Abstract03/Abtract03_Normal.ktx2',
            // BottomArtModelTwo
            '/leading/leadingPageAssets/models/Bottom/Abstract02/Abstract02_Albeto.ktx2',
            '/leading/leadingPageAssets/models/Bottom/Abstract02/Abstract02_Lightmap.ktx2',
            '/leading/leadingPageAssets/models/Bottom/Abstract02/Abstract02_Normal.ktx2'
        ];
    }, []);
};

export default useLoadTexturePath;
