import useDracoLoader from 'hooks/useDracoLoader';
import BasicModel from '../BasicModel/BasicModel';
import { useDetectGPU } from '@react-three/drei';
import { memo } from 'react';

const BtmGold = () => {
    const { isMobile } = useDetectGPU();

    const checkData = isMobile
        ? '/leading/leadingPageAssets/models/Bottom/mobile/Floor/BtmFloorGold.gltf'
        : '/leading/leadingPageAssets/models/Bottom/Floor/BtmFloorGold.gltf';
    const gltf = useDracoLoader(checkData);

    return (
        <>
            {gltf.scene.children.map((item: any, index: any) => {
                return (
                    <BasicModel
                        key={index}
                        item={item}
                        maps={{
                            reflectivity: 0.84,
                            roughness: 0.41,
                            metalness: 0.81,
                            color: '#DBB52B'
                            // map,
                            // normalMap,
                            // roughnessMap,
                            // reflectivity: 0.14,
                            // roughness: 0.78,
                            // normalScale: new Vector2(1.14, -0.22)
                        }}
                        layers={1}
                    />
                );
            })}
        </>
    );
};

export default memo(BtmGold);
