import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

const useContactDialog = () => {
    const { t, i18n } = useTranslation();
    const SUBJECT = useMemo(
        () => [
            { name: t('GameAPI'), value: t('GameAPI') },
            { name: t('WhiteLabelSolution'), value: t('WhiteLabelSolution') }
        ],
        [i18n.language, t]
    );

    // 國家 ＋ 國碼
    const COUNTRY = [
        {
            name: t('Taiwan'),
            value: t('Taiwan'),
            code: '886',
            id: 1
        },
        {
            name: t('USA'),
            value: t('USA'),
            code: '1',
            id: 2
        },
        {
            name: t('MainlandChina'),
            value: t('MainlandChina'),
            code: '86',
            id: 3
        },
        {
            name: t('Argentina'),
            value: t('Argentina'),
            code: '54',
            id: 4
        },
        {
            name: t('Belize'),
            value: t('Belize'),
            code: '501',
            id: 5
        },
        {
            name: t('Brazil'),
            value: t('Brazil'),
            code: '55',
            id: 6
        },
        {
            name: t('Bolivia'),
            value: t('Bolivia'),
            code: '591',
            id: 7
        },
        {
            name: t('Chile'),
            value: t('Chile'),
            code: '56',
            id: 8
        },
        {
            name: t('ElSalvador'),
            value: t('ElSalvador'),
            code: '503',
            id: 9
        },
        {
            name: t('Panama'),
            value: t('Panama'),
            code: '507',
            id: 10
        },
        {
            name: t('Venezuela'),
            value: t('Venezuela'),
            code: '58',
            id: 11
        },
        {
            name: t('Bahrain'),
            value: t('Bahrain'),
            code: '973',
            id: 12
        },
        {
            name: t('Bangladesh'),
            value: t('Bangladesh'),
            code: '880',
            id: 13
        },
        {
            name: t('Brunei'),
            value: t('Brunei'),
            code: '673',
            id: 14
        },
        {
            name: t('Cambodia'),
            value: t('Cambodia'),
            code: '855',
            id: 15
        },
        {
            name: t('Guam'),
            value: t('Guam'),
            code: '1671',
            id: 16
        },
        {
            name: t('HongKong'),
            value: t('HongKong'),
            code: '852',
            id: 17
        },
        {
            name: t('India'),
            value: t('India'),
            code: '91',
            id: 18
        },
        {
            name: t('Indonesia'),
            value: t('Indonesia'),
            code: '62',
            id: 19
        },
        {
            name: t('Israel'),
            value: t('Israel'),
            code: '972',
            id: 20
        },
        {
            name: t('Japan'),
            value: t('Japan'),
            code: '81',
            id: 21
        },
        {
            name: t('Jordan'),
            value: t('Jordan'),
            code: '962',
            id: 22
        },
        {
            name: t('SouthKorea'),
            value: t('SouthKorea'),
            code: '82',
            id: 23
        },
        {
            name: t('Kuwait'),
            value: t('Kuwait'),
            code: '965',
            id: 24
        },
        {
            name: t('Macau'),
            value: t('Macau'),
            code: '853',
            id: 25
        },
        {
            name: t('Malaysia'),
            value: t('Malaysia'),
            code: '60',
            id: 26
        },
        {
            name: t('Maldives'),
            value: t('Maldives'),
            code: '960',
            id: 27
        },
        {
            name: t('Mongolia'),
            value: t('Mongolia'),
            code: '976',
            id: 28
        },
        {
            name: t('Oman'),
            value: t('Oman'),
            code: '968',
            id: 29
        },
        {
            name: t('Pakistan'),
            value: t('Pakistan'),
            code: '92',
            id: 30
        },
        {
            name: t('Philippines'),
            value: t('Philippines'),
            code: '63',
            id: 31
        },
        {
            name: t('SaudiArabia'),
            value: t('SaudiArabia'),
            code: '966',
            id: 32
        },
        {
            name: t('Singapore'),
            value: t('Singapore'),
            code: '65',
            id: 33
        },
        {
            name: t('SriLanka'),
            value: t('SriLanka'),
            code: '94',
            id: 34
        },
        {
            name: t('Syria'),
            value: t('Syria'),
            code: '963',
            id: 35
        },
        {
            name: t('Thailand'),
            value: t('Thailand'),
            code: '66',
            id: 36
        },
        {
            name: t('Turkey'),
            value: t('Turkey'),
            code: '90',
            id: 37
        },
        {
            name: t('U.A.E.'),
            value: t('U.A.E.'),
            code: '971',
            id: 38
        },
        {
            name: t('Vietnam'),
            value: t('Vietnam'),
            code: '84',
            id: 39
        },
        {
            name: t('Australia'),
            value: t('Australia'),
            code: '61',
            id: 40
        },
        {
            name: t('NewZealand'),
            value: t('NewZealand'),
            code: '64',
            id: 41
        },
        {
            name: t('Albania'),
            value: t('Albania'),
            code: '355',
            id: 42
        },
        {
            name: t('Austria'),
            value: t('Austria'),
            code: '43',
            id: 43
        },
        {
            name: t('Azerbaijan'),
            value: t('Azerbaijan'),
            code: '994',
            id: 44
        },
        {
            name: t('Belgium'),
            value: t('Belgium'),
            code: '32',
            id: 45
        },
        {
            name: t('Bulgaria'),
            value: t('Bulgaria'),
            code: '359',
            id: 46
        },
        {
            name: t('Croatia'),
            value: t('Croatia'),
            code: '385',
            id: 47
        },
        {
            name: t('Czech'),
            value: t('Czech'),
            code: '420',
            id: 48
        },
        {
            name: t('Denmark'),
            value: t('Denmark'),
            code: '45',
            id: 49
        },
        {
            name: t('Estonia'),
            value: t('Estonia'),
            code: '372',
            id: 50
        },
        {
            name: t('Finland'),
            value: t('Finland'),
            code: '358',
            id: 51
        },
        {
            name: t('France'),
            value: t('France'),
            code: '33',
            id: 52
        },
        {
            name: t('Georgia'),
            value: t('Georgia'),
            code: '995',
            id: 53
        },
        {
            name: t('Germany'),
            value: t('Germany'),
            code: '49',
            id: 54
        },
        {
            name: t('Gibraltar'),
            value: t('Gibraltar'),
            code: '350',
            id: 55
        },
        {
            name: t('Greece'),
            value: t('Greece'),
            code: '30',
            id: 56
        },
        {
            name: t('Hungary'),
            value: t('Hungary'),
            code: '36',
            id: 57
        },
        {
            name: t('Iceland'),
            value: t('Iceland'),
            code: '354',
            id: 58
        },
        {
            name: t('Ireland'),
            value: t('Ireland'),
            code: '353',
            id: 59
        },
        {
            name: t('Italy'),
            value: t('Italy'),
            code: '39',
            id: 60
        },
        {
            name: t('Kazakhstan'),
            value: t('Kazakhstan'),
            code: '7',
            id: 61
        },
        {
            name: t('Latvia'),
            value: t('Latvia'),
            code: '371',
            id: 62
        },
        {
            name: t('Lithuania'),
            value: t('Lithuania'),
            code: '370',
            id: 63
        },
        {
            name: t('Luxembourg'),
            value: t('Luxembourg'),
            code: '352',
            id: 64
        },
        {
            name: t('Malta'),
            value: t('Malta'),
            code: '356',
            id: 65
        },
        {
            name: t('Netherlands'),
            value: t('Netherlands'),
            code: '31',
            id: 66
        },
        {
            name: t('Norway'),
            value: t('Norway'),
            code: '47',
            id: 67
        },
        {
            name: t('Poland'),
            value: t('Poland'),
            code: '48',
            id: 68
        },
        {
            name: t('Romania'),
            value: t('Romania'),
            code: '40',
            id: 69
        },
        {
            name: t('Russia'),
            value: t('Russia'),
            code: '7',
            id: 70
        },
        {
            name: t('Slovakia'),
            value: t('Slovakia'),
            code: '421',
            id: 71
        },
        {
            name: t('Slovenia'),
            value: t('Slovenia'),
            code: '386',
            id: 72
        },
        {
            name: t('Spain'),
            value: t('Spain'),
            code: '34',
            id: 73
        },
        {
            name: t('Sweden'),
            value: t('Sweden'),
            code: '46',
            id: 74
        },
        {
            name: t('Switzerland'),
            value: t('Switzerland'),
            code: '41',
            id: 75
        },
        {
            name: t('UK'),
            value: t('UK'),
            code: '44',
            id: 76
        },
        {
            name: t('Ukraine'),
            value: t('Ukraine'),
            code: '380',
            id: 77
        },
        {
            name: t('Uzbekistan'),
            value: t('Uzbekistan'),
            code: '998',
            id: 78
        },
        {
            name: t('Cote'),
            value: t('Cote'),
            code: '225',
            id: 79
        },
        {
            name: t('Egypt'),
            value: t('Egypt'),
            code: '20',
            id: 80
        },
        {
            name: t('Kenya'),
            value: t('Kenya'),
            code: '254',
            id: 81
        },
        {
            name: t('Mauritius'),
            value: t('Mauritius'),
            code: '230',
            id: 82
        },
        {
            name: t('Mozambique'),
            value: t('Mozambique'),
            code: '258',
            id: 83
        },
        {
            name: t('Namibia'),
            value: t('Namibia'),
            code: '264',
            id: 84
        },
        {
            name: t('SouthAfrica'),
            value: t('SouthAfrica'),
            code: '27',
            id: 85
        },
        {
            name: t('Sudan'),
            value: t('Sudan'),
            code: '249',
            id: 86
        },
        {
            name: t('Swaziland'),
            value: t('Swaziland'),
            code: '268',
            id: 87
        },
        {
            name: t('Uganda'),
            value: t('Uganda'),
            code: '256',
            id: 88
        },
        {
            name: t('Zimbabwe'),
            value: t('Zimbabwe'),
            code: '263',
            id: 89
        }
    ];

    return { SUBJECT, COUNTRY };
};

export default useContactDialog;
