import BtmGroundSet from '../BtmGroundSet';
import HallwayTwoPc from './HallwayTwoPc/HallwayTwoPc';
import { memo } from 'react';
const HallwayTwo = ({ groupRef }: any) => {
    return (
        <group ref={groupRef as any}>
            <HallwayTwoPc />
            <BtmGroundSet />
        </group>
    );
};

export default memo(HallwayTwo);
