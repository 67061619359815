import BasicModel from 'components/models/BasicModel/BasicModel';
import { memo } from 'react';
import useDracoLoader from 'hooks/useDracoLoader';
import { Vector2 } from 'three';
import { useDetectGPU } from '@react-three/drei';
import { useMainContext } from 'hooks/useMainContext';

const BtmWall = () => {
    const { isMobile } = useDetectGPU();

    const checkData = isMobile
        ? '/leading/leadingPageAssets/models/Bottom/mobile/Wall/BtmWall.gltf'
        : '/leading/leadingPageAssets/models/Bottom/Wall/BtmWall.gltf';
    const gltf = useDracoLoader(checkData);

    const { textures } = useMainContext();
    const { btmWallmap, btmWallnormalMap, btmWallenvMap } = textures;

    return (
        <>
            {gltf.scene.children.map((item: any, index: any) => {
                return (
                    <BasicModel
                        key={index}
                        item={item}
                        maps={{
                            map: btmWallmap,
                            envMap: btmWallenvMap,
                            normalMap: btmWallnormalMap,

                            reflectivity: 0.46,
                            roughness: 0.6,
                            metalness: 0.7,
                            normalScale: new Vector2(0.45, -0.38)
                        }}
                        layers={1}
                    />
                );
            })}
        </>
    );
};

export default memo(BtmWall);
