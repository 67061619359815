import { Html } from '@react-three/drei';
import './detailDialog.scss';
import { ReactComponent as Cross } from '../../../assets/icons/X.svg';
import { handleAllYtVideoClose } from 'utils/handleAllYtVideoClose';
import { memo } from 'react';

const DetailDialog = ({
    children,
    status,
    setStatusFalse,
    center,
    contentStatus
}: any) => {
    const handleClose = () => {
        if (contentStatus === false) return;
        // dialog.scrollTo({ top: 0 });
        setStatusFalse();
        center.scrollPause = false;
        handleAllYtVideoClose();

        setTimeout(() => {
            const dialogs: any = document.querySelectorAll(
                '.detailDialog-scrollbar'
            );
            dialogs.forEach((item: any) => {
                item.scrollTo({ top: 0 });
            });
        }, 500);
    };
    return (
        <>
            <Html
                wrapperClass="ui_Btn"
                layers={10}
            >
                <div
                    className={`detailDialog ${
                        status ? 'showDialog' : 'closeDialog'
                    }`}
                    style={{
                        border: '2px solid white',
                        transform: status ? 'scale(1)' : 'scale(0)',
                        opacity: status ? '1' : '0',
                        pointerEvents: 'visible'
                    }}
                >
                    <button
                        className="cross-btn"
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        <Cross />
                    </button>
                    <div className="detailDialog-scrollbar">{children}</div>
                </div>
                {status && (
                    <div
                        className="backdrop"
                        onClick={(e) => {
                            handleClose();
                        }}
                    />
                )}
            </Html>
        </>
    );
};

export default memo(DetailDialog);
