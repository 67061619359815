import useLoadTexturePath from './useLoadTexturePath';

const usePreload = () => {
    const textureKtx2Group = useLoadTexturePath(); //16 items
    // const textureGroup = useLoadTexturePath();

    // 共31
    const texturePreload = [
        '/leading/leadingPageAssets/images/cloud/cloud01.png',
        '/leading/leadingPageAssets/images/cloud/cloud02.png',
        '/leading/leadingPageAssets/images/cloud/cloud03.png',

        '/leading/leadingPageAssets/images/hallway/AboutBBIN/About-BBIN-cover.jpg',
        '/leading/leadingPageAssets/images/hallway/IGA/IGA_1100x730.jpg',
        '/leading/leadingPageAssets/images/hallway/EGR/EGR_1100x730.jpg',
        '/leading/leadingPageAssets/images/hallway/AGA/AGA_1100x730.jpg',
        '/leading/leadingPageAssets/images/hallway/EuropeGamingAwards/Europe-Gaming-Awards_1100x730.jpg',
        '/leading/leadingPageAssets/images/hallway/StarletAwards/Starlet-Awards_1100x730.jpg',
        '/leading/leadingPageAssets/images/hallway/SPiCEPhilippines/SPiCE-Philippines_1100x730.jpg',

        '/leading/leadingPageAssets/images/hallway/year/2019.jpg',
        '/leading/leadingPageAssets/images/hallway/year/2020.jpg',
        '/leading/leadingPageAssets/images/hallway/year/2021.jpg',
        '/leading/leadingPageAssets/images/hallway/year/2022.jpg',
        '/leading/leadingPageAssets/images/hallway/year/2023/2023.jpg',
        ...textureKtx2Group
    ];
    
    const gltfPreload = [
        '/leading/leadingPageAssets/models/Bottom/Floor/BtmFloor.gltf',
        '/leading/leadingPageAssets/models/Bottom/Floor/BtmFloorGold.gltf',
        '/leading/leadingPageAssets/models/Bottom/Wall/BtmWall.gltf',
        '/leading/leadingPageAssets/models/Bottom/Abstract01/Abstract01.gltf',
        '/leading/leadingPageAssets/models/Bottom/Abstract02/Abstract02.gltf',
        '/leading/leadingPageAssets/models/Bottom/Abstract03/Abstract03.gltf'
    ];

    return { texturePreload, gltfPreload };
};

export default usePreload;
