import { useRef } from 'react';
import { useFrame } from '@react-three/fiber';

export const useRotate = () => {
    const ref = useRef<any>();
    const offset = Math.random() *100;

    useFrame((staste) => {
        if (!ref.current) return;

        const time = staste.clock.getElapsedTime();
        const _ref = ref.current;
        _ref.rotation.z = offset + time / 12;
        // rrefff.current.rotation.z = -t / 1.618;
    });

    return { ref };
};
