import { useEffect, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { ResizeObserver } from '@juggle/resize-observer';
import ScreenOrientation from 'components/ScreenOrientation/ScreenOrientation';
import CanvasContainer from './CanvasContainer/CanvasContainer';


const CanvasEntrace = ({
    hallwayPositionX,
    setCanvasStatus,
    setHallwayToLoby,
    setVideoStatus,
    setCountNum
}: any) => {
    const canvasRef = useRef<any>();

    const hamdleResize = () => {
        if (!canvasRef.current) return;
        canvasRef.current.style.width = window.innerWidth;
        canvasRef.current.style.height = window.innerHeight;
    };

    useEffect(() => {

        window.addEventListener('resize', hamdleResize);

        return () => {
            window.removeEventListener('resize', hamdleResize);
        };
    }, []);

    return (
        <>
            {/* 如果使用 Suspense, app組件倒數完畢後, 會看到canvas場景, 但canvas場景尚未準備好,就會發生: 畫面一片白的情況 */}
            {/* <Suspense> */}
                <Canvas
                    ref={canvasRef}
                    flat
                    shadows={false}
                    className="experience"
                    camera={{
                        fov: 30,
                        near: 0.1,
                        far: 3300
                    }}
                    gl={{
                        antialias: true, 
                        // preserveDrawingBuffer: true,
                        // logarithmicDepthBuffer: true,
                        powerPreference: 'high-performance'
                    }}
                    dpr={[1, 1]}
                    resize={{ polyfill: ResizeObserver }}
                >   
                    <CanvasContainer
                        hallwayPositionX={hallwayPositionX}
                        setCanvasStatus={setCanvasStatus}
                        setHallwayToLoby={setHallwayToLoby}
                        // 
                        setVideoStatus={setVideoStatus}
                        setCountNum={setCountNum}
                    />
                </Canvas>
                <ScreenOrientation />
            {/* </Suspense> */}
            
        </>
    );
};

export default CanvasEntrace;
