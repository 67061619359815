import { useContext } from 'react';
import { MainContext } from '../components/CanvasEntrace/CanvasContainer/CanvasContainer';
import { CamMovingCenter } from '../features/CamMovingCenter/CamMovingCenter';
import { TextureGroup } from 'features/CamMovingCenter/hook/types/TextureGroup';

export interface MainContextTypes {
    center: CamMovingCenter;
    handleMouseMove: () => void;
    textures: TextureGroup;
}

export const useMainContext = () => {
    const main: MainContextTypes = useContext(MainContext);

    return main;
};
