import { makeCloudSmokeObj } from 'components/models/CloudSmoke/makeCloudSmokeObj';
import { useMemo } from 'react';
const useHallwayTwo = () => {
    const ARR2019 = useMemo(
        () => [
            makeCloudSmokeObj([4.7, -82.5, -190], 0.3, 0.2),
            makeCloudSmokeObj([6.8, -82, -190.2], 0.35, 0.3),
            makeCloudSmokeObj([-4.5, -83, -189], 0.3, 0.1),
            makeCloudSmokeObj([-5.7, -82, -189.1], 0.3, 0.3)
        ],
        []
    );
    const ARR2021 = useMemo(
        () => [
            makeCloudSmokeObj([-3.3, -83, -147.1], 0.3, 0.15),
            makeCloudSmokeObj([-4.5, -82, -147.2], 0.3, 0.4)
        ],
        []
    );
    const ARR2023 = useMemo(
        () => [
            makeCloudSmokeObj([4.7, -82.5, -110.1], 0.3, 0.2),
            makeCloudSmokeObj([6.8, -82, -110.2], 0.35, 0.3),
            makeCloudSmokeObj([-2, -82.5, -110], 0.2, 0.25),
            makeCloudSmokeObj([-0.5, -82.8, -109.9], 0.3, 0.5)
        ],
        []
    );

    return { ARR2019, ARR2021, ARR2023 };
};

export default useHallwayTwo;
