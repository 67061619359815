import { memo } from 'react';
const MobileCarouselText = ({ visibility, hallwaySection }: any) => {
    return (
        <div
            style={{
                visibility: visibility ? 'visible' : 'hidden',
                opacity: visibility ? '1' : '0',
                transition: '0.6s'
            }}
        >
            {hallwaySection === 0 && (
                <div className="text-container">
                    <div
                        style={{
                            transition: '0.6s',
                            color: '#fff',
                            transform: visibility
                                ? 'translate(0,0)'
                                : 'translate(0,120%)'
                        }}
                    >
                        <p
                            className="title"
                            style={{ opacity: '0' }}
                        >
                            hidden
                        </p>
                        <p className="title">About BBIN</p>
                        <p className="years">since 1999</p>
                    </div>
                </div>
            )}
            {hallwaySection === 1 && (
                <div className="text-container">
                    <div
                        style={{
                            transition: '0.6s',
                            color: '#ef8e35',
                            transform: visibility
                                ? 'translate(0,0)'
                                : 'translate(0,120%)'
                        }}
                    >
                        <p className="title">Yearly</p>
                        <p className="title">Brand Vision</p>
                        <p className="years">2019 - 2023</p>
                    </div>
                </div>
            )}
            {hallwaySection === 2 && (
                <div className="text-container">
                    <div
                        style={{
                            transition: '0.6s',
                            color: '#4cd8c0',
                            transform: visibility
                                ? 'translate(0,0)'
                                : 'translate(0,120%)'
                        }}
                    >
                        <p className="title">Honors</p>
                        <p className="title">and Awards</p>
                        <p className="years">2016 - 2023</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default memo(MobileCarouselText);
