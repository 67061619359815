import { CamMovingCenter } from '../CamMovingCenter';
import useMakeAtoB from '../hook/useMakeAtoB';
// import { deepClone } from 'utils/deepClopne';
import { useTransitions } from '../hook/useTransitions';
import { useMemo } from 'react';
import { Vector2, Vector3 } from 'three';
// import { useRef} from 'react';

const useLobyToHallway = (center: CamMovingCenter) => {
    // const brightnessRef = useRef(null);

    const position_y = useMemo(() => {
        return -83;
    }, []);

    // const handleToHallway = useMakeAtoB(
    //     { target: { y: 8, z: -15 }, duration: 3 },
    //     center
    // );
    const handleArriveHallway = useMakeAtoB(
        { target: { y: position_y, z: 10.2 }, duration: 3 },
        center
    );

    const { handleBrightnessLess, handleBrightness } = useTransitions(center);

    const lobyToHallway = () => {
        // 畫面正在移動的話，就返回
        if (center.isMoving) return;

        center.handleMoving(true);
        center.hallwayPercentOrgine = new Vector2(0, 0); //y,z
        center.hallwayPercentTarget = new Vector2(0, 0); //y,z

        center.scrollPause = true;
        center.handleChangePosition('hallway');

        // handleBrightness(); 

        setTimeout(() => {
            center.isFogOpen = true;
            center.camera.layers.enable(1);
            center.camera.layers.disable(0);
            center.defaultCameraRotation.x = 3;
            center.cameraPosition = new Vector3(0, position_y, 30.2);
            handleArriveHallway({ x: 0, y: position_y, z: 30.2 }); //這裡座標是設定origin, 50是從更遠的地方拉近
            
            handleBrightnessLess();//這裡將曝光值由1變成0
        }, 0); 

        setTimeout(() => {
            center.handleMoving(false);
            center.scrollPause = false;
        }, 3100); //3100
    };

    return lobyToHallway;
};

export default useLobyToHallway;