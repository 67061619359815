import { CamMovingCenter } from '../CamMovingCenter';
import { deepClone } from 'utils/deepClopne';
import useMakeAtoB from '../hook/useMakeAtoB';
import { useTransitions } from '../hook/useTransitions';
import { useDetectGPU } from '@react-three/drei';
import { Vector3 } from 'three';
// import { useRef} from 'react';

const useHallwayToLoby = (center: CamMovingCenter) => {
    const { handleBrightness, handleBrightnessLess } = useTransitions(center);
    
    const { isMobile } = useDetectGPU();
    const handleToLoby = useMakeAtoB(
        {
            target: { y: -83, z: -30 },
            duration: 3
        },
        center
    );

    const handleArriveLoby = useMakeAtoB(
        {
            target: { y: 2, z: isMobile ? 15 : 10.2 },
            duration: 3
        },
        center
    );

    const hallwayToLoby = () => {
        if (center.isMoving || center.where === 'loby') return;
        const cameraPositionNow = deepClone(center.camera.position);

        center.handleMoving(true);
        center.scrollPause = true;
        handleToLoby(cameraPositionNow);

        setTimeout(() => {
            handleBrightness(); //將曝光值恢復成1
        }, 500);
        
        // 因為要抵達後才開啟scroll控制所以settimeout
        setTimeout(() => {
            center.camera.layers.enable(0);
            center.camera.layers.disable(1);
            center.cameraPosition = new Vector3(0, 3, 52);
            center.cameraPositionSmooth = new Vector3(
                0,
                2,
                isMobile ? 15 : 10.2
            );
            center.defaultCameraRotation.x = 5;
            handleArriveLoby({ x: 0, y: 3, z: 52 });
            center.lobyScrollPercent = 1;

            center.isFogOpen = false;

            // handleBrightnessLess();

            center.handleChangePosition('loby');
            center.scrollPause = false;
            center.handleMoving(false);

            // 原本是用reload方式
            // window.location.reload();  


        }, 1000);
    };

    return hallwayToLoby;
};

export default useHallwayToLoby;