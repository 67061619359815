import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

const MobileCarouselBtnGroup = ({
    carouselList,
    carouselStatus,
    setCarouselStatus
}: any) => {
    return (
        <>
            {carouselList.length !== 1 && (
                <button
                    className="mobileCarousel-left"
                    onClick={() => {
                        if (carouselStatus === 0) {
                            setCarouselStatus(carouselList.length - 1);
                            return;
                        }
                        setCarouselStatus((_state: any) => {
                            return _state - 1;
                        });
                    }}
                >
                    <ArrowBackRoundedIcon />
                </button>
            )}

            {carouselList.length !== 1 && (
                <button
                    className="mobileCarousel-right"
                    onClick={() => {
                        if (carouselList.length === carouselStatus + 1) {
                            setCarouselStatus(0);
                            return;
                        }
                        setCarouselStatus((_state: any) => {
                            return _state + 1;
                        });
                    }}
                >
                    <ArrowForwardRoundedIcon />
                </button>
            )}
        </>
    );
};

export default MobileCarouselBtnGroup;
