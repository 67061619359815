import {
    // StatsGl,
    Stats,
    AdaptiveEvents,
    Environment,
    // OrbitControls,
    Preload,
    useEnvironment
} from '@react-three/drei';
import LighterModel from '../../models/LighterModel/LighterModel';
import Cam from '../../models/Cam/Cam';
// import { useControls } from 'leva';
import { memo, useRef} from 'react';
import {
    EffectComposer,
    BrightnessContrast,
    SMAA
} from '@react-three/postprocessing';
import { useFrame } from '@react-three/fiber';
import { useMainContext } from 'hooks/useMainContext';
import { Perf } from 'r3f-perf';


const CanvasSetting = () => {
    const { center } = useMainContext();
    const envMap = useEnvironment({ files: '/leading/leadingPageAssets/skyBox/midday.hdr' });
    
    const brightnessRef = useRef<any>();

    useFrame(() => {
        if (!brightnessRef) return;
        brightnessRef.current.brightness = center.brightness;
        // console.log('brightness',center.brightness);
    });

    return (
        <>   
            {
                process.env.NODE_ENV === 'development' && 
                (<>
                    <Perf 
                        position="bottom-left" 
                        debug={true}
                    />
                    <Stats showPanel={0} />
                </>)
            }
            
            <Preload all />
            <Environment map={envMap} />
            <Cam />
            <LighterModel />
            <AdaptiveEvents />

            <EffectComposer>
                <BrightnessContrast
                    ref={brightnessRef}
                    brightness={0}
                />
                <SMAA />
            </EffectComposer>
        </>
    );
};

export default memo(CanvasSetting);
