import { useRef, useEffect, memo } from 'react';
import { gsap, Back } from 'gsap';
import './mobile-detailDialog.scss';
const MobileCarouselItem = ({
    item,
    index,
    carouselStatus,
    hallwaySection,
    handleDialogOpen
}: any) => {
    const ref = useRef<any>();

    const handleTranslate = (index: number) => {
        const check = index === carouselStatus;

        gsap.to(ref.current, {
            duration: 0.7,
            ease: Back.easeOut.config(0.75),
            transform: `translate(calc(${10 - carouselStatus * 80}vw + ${
                25 * index - carouselStatus * 25
            }px), 0) scale(${check ? 1.05 : 1})`
        });
    };

    useEffect(() => {
        handleTranslate(index);

        // if (videoRef.current) {
        //     videoRef.current.play();
        // }
    }, [carouselStatus]);

    return (
        <>
            <div
                ref={ref}
                className="mobileCarousel-item"
                // style={{
                //     transform: handleTranslate(index)
                // }}
                onClick={() => {
                    handleDialogOpen(index);
                }}
            >
                {/* {item.type === 'image' && (
                    )} */}
                <img
                    src={item.src}
                    alt=""
                />
                {/* {item.type === 'video' && (
                    <video
                        className="mobileCarouselItem-video"
                        autoPlay
                        loop
                        muted
                        playsInline
                        src={item.src}
                    />
                )} */}

                {item.title !== 'BBIN' && (
                    <div className="mobileCarousel-item-cover">
                        {hallwaySection === 2 && <p className="width-smaller">{item.title}</p>}
                        {hallwaySection !== 2 && (
                            <>
                                <p>{item.title}</p>
                                <p>{item.subtitle}</p>
                            </>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default memo(MobileCarouselItem);
