import { memo } from 'react';
// import { useControls } from 'leva';

const LighterModel = () => {
    // const { color, groundColor, intensity } = useControls({
    //     color: { value: '#f5c4c3' },
    //     groundColor: { value: '#F9E1F7' },
    //     intensity: { value: 1, step: 0.01 }
    // });
    return (
        <group>
            <hemisphereLight
                position={[0, 10.606, 23.133]}
                intensity={1}
                // intensity={intensity}
                color={'#F2eaea'}
                // color={color}
                groundColor={'#bebebe'}
                // groundColor={groundColor}
                layers={2}
            />
            <directionalLight
                intensity={1}
                position={[0, 4.306, 49.986]}
                layers={2}
            />
        </group>
    );
};

export default memo(LighterModel);
