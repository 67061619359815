import './inputText.scss';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const InputText = ({ id, label, required = false }: any) => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors },
        clearErrors
    } = useFormContext();

    return (
        <div className="inputText">
            <input
                className={errors[id] ? 'error' : ''}
                type="text"
                placeholder={
                    errors[id] ? (t('isRequired') as any) : (label as string)
                }
                onClick={() => {
                    clearErrors(id);
                }}
                {...register(id, { required: required })}
            />
        </div>
    );
};

export default InputText;
