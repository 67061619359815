/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import InputText from 'components/inputs/InputText/InputText';
import SelectInput from 'components/inputs/SelectInput/SelectInput';
import InputRadio from 'components/inputs/InputRadio/InputRadio';
import { ReactComponent as Cross } from '../../assets/icons/X.svg';
import { useForm, FormProvider } from 'react-hook-form';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { handleSubmit } from './handleSubmit';
import { useTranslation } from 'react-i18next';
import useContactDialog from './useContactDialog';
import './contactDialog.scss';

const ContactDialog = ({
    contactDialog,
    setContactDialog,
    canCancel,
    setCanCancel
}: any) => {
    const { SUBJECT, COUNTRY } = useContactDialog();
    const { t, i18n } = useTranslation();
    const methods = useForm();
    const [captchaSrc, setCaptchaSrc] = useState(null);
    const [submitLock, setSubmitLock] = useState(false);
    // const [laravelSession, setLaravelSession] = useState(null);

    const handleClose = () => {
        if (!canCancel) return;
        setCanCancel(false);
        setContactDialog(false);
        // center.scrollPause = false;
        methods.reset();
    };

    const onSubmit = async (data: any) => {
        if (submitLock) return;
        setSubmitLock(true);
        handleSubmit(i18n.language, data, COUNTRY, methods.reset, handleGetCaptcha, setSubmitLock);
    };

    // const getCookie = (cookie: any) => {
    //     const data: any = {};
    //     const cookieList = cookie.split(';');

    //     for (let i = 0; i < cookieList.length; i += 1) {
    //         let cookie = cookieList[i];

    //         while (cookie.charAt(0) === ' ') cookie = cookie.substring(1);

    //         const cookieSplittedByEqual = cookie.split('=');

    //         data[cookieSplittedByEqual[0]] = cookieSplittedByEqual[1];
    //     }

    //     return data;
    // };

    const handleGetCaptcha = async () => {
        axios({
            url: '/captcha/api/bbin',
            method: 'GET'
        }).then((res: any) => {
            setCaptchaSrc(res.data.img);
            methods.setValue('captcha', '');
        });
    };

    useEffect(() => {
        if (contactDialog) {
            handleGetCaptcha();
        }
    }, [contactDialog]);

    return (
        <FormProvider {...methods}>
            <form
                style={
                    contactDialog
                        ? {
                            visibility: 'visible'
                            // opacity: '1'
                            // transform: 'translateX(0px)'
                        }
                        : {
                            visibility: 'hidden'
                            // opacity: '0'
                            // transform: 'translateX(300px)'
                        }
                }
                className={`contactFormDailog ${
                    contactDialog ? 'contactDialog_open' : 'contactDialog_close'
                }`}
                onSubmit={methods.handleSubmit(onSubmit)}
                onReset={methods.reset}
            >
                <button
                    type="button"
                    className="cross_btn"
                    onClick={handleClose}
                >
                    <Cross />
                </button>

                <div className="title_group">
                    <h3>{t('Contact Us')}</h3>
                    <p>
                        {t('contact-1')}
                        <span
                            style={{
                                fontFamily: 'MyriadVariableConcept',
                                fontWeight: 'bold'
                            }}
                        >
                            {t('BBIN')}
                        </span>
                        {t('contact-2')}
                    </p>
                    <p>{t('contact-3')}</p>
                    <p>{`(${t('contact-4')})`}</p>
                </div>
                <div className="row">
                    <InputText
                        id="name"
                        label={t('title')}
                        required={true}
                    />
                </div>
                <div className="row">
                    <SelectInput
                        id={'country'}
                        label={t('Country')}
                        list={COUNTRY}
                        required={true}
                    />
                    <InputText
                        id={'tel'}
                        label={t('PhoneNumber')}
                        required={true}
                    />
                </div>
                <div style={{ paddingBottom: '1rem' }}>
                    <InputText
                        id={'email'}
                        label={t('Email')}
                        required={true}
                    />
                </div>
                <div className="row">
                    <InputText
                        id={'msg_id'}
                        label={t('IDForInstantMessagingApp')}
                        required={true}
                    />
                </div>
                <div className="row">
                    <InputRadio
                        id={'subject'}
                        list={SUBJECT}
                        label={t('Subject')}
                    />
                </div>

                <div style={{ paddingBottom: '1rem' }}>
                    <div className="inputTextArea">
                        <textarea
                            className={
                                methods.formState.errors['content']
                                    ? 'error'
                                    : ''
                            }
                            placeholder={
                                methods.formState.errors['content']
                                    ? (t('isRequired') as any)
                                    : (t(
                                        'PleaseEnterYourMessageContentHere'
                                    ) as string)
                            }
                            onClick={() => {
                                methods.clearErrors('content');
                            }}
                            {...methods.register('content', { required: true })}
                        />
                    </div>
                </div>

                <div className="row">
                    <InputText
                        id={'captcha'}
                        label={t('Captcha')}
                        required={true}
                    />
                    {captchaSrc && (
                        <div
                            style={{
                                flex: '0.5',
                                padding: '0 1rem',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                            }}
                            onClick={handleGetCaptcha}
                        >
                            <img
                                src={captchaSrc}
                                alt="Verification code"
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                    )}
                </div>

                <div className="row">
                    <button
                        className="sub_btn"
                        type="reset"
                    >
                        {t('Refill')}
                    </button>
                    <button
                        className="sub_btn"
                        disabled={submitLock ? true : false}
                    >
                        {submitLock ? t('Sending') + '...' : t('Send')}
                    </button>
                </div>
            </form>
            <div
                style={
                    contactDialog
                        ? {
                            backgroundColor: '#000000',
                            opacity: '0.5',
                            transition: '0.5s',
                            visibility: 'visible',
                            zIndex: 16352619,
                            pointerEvents: 'visible'
                        }
                        : {
                            backgroundColor: '#000000',
                            opacity: '0',
                            transition: '0.5s',
                            visibility: 'hidden',
                            zIndex: 16352619,
                            pointerEvents: 'visible'
                        }
                }
                className="backdrop"
                onClick={handleClose}
            />
        </FormProvider>
    );
};

export default ContactDialog;
