import DetailDialog from '../DetailDialog/DetailDialog';
import { useMainContext } from 'hooks/useMainContext';
import { memo } from 'react';

interface HallwayVideoProps {
    title: string;
    subtitle?: string;
    dialogStatus: boolean;
    setDialogStatus: any;
    contentStatus: any;
    setContentStatus: any;
    src: string;
    children?: any;
    type: 'image' | 'youtube';
}

const HallwayVideoDialog = ({
    title,
    subtitle,
    dialogStatus,
    setDialogStatus,
    contentStatus,
    setContentStatus,
    src,
    children,
    type
}: HallwayVideoProps) => {
    const { center } = useMainContext();
    const handleTitleColor = () => {
        const checkHallwayPart = center.hallwayfogStatus;

        if (checkHallwayPart === 0) {
            return '#B92A24';
        }
        if (checkHallwayPart === 1) {
            return '#ef8e35';
        }
        if (checkHallwayPart === 2) {
            return '#4cd8c0';
        }
    };
    return (
        <DetailDialog
            status={dialogStatus}
            contentStatus={contentStatus}
            setStatusFalse={() => {
                setDialogStatus(false);
                setContentStatus(false);
            }}
            center={center}
        >
            <div className="aboutBbin">
                <div
                    style={{
                        // height: '40px',
                        overflow: 'hidden',
                        position: 'relative',
                        marginBottom: '0.5rem'
                    }}
                >
                    <h2
                        className="aboutBbin-title"
                        style={{
                            transform: contentStatus
                                ? 'translate(0, 0)'
                                : 'translate(0, 100%)',
                            color: handleTitleColor()
                        }}
                    >
                        <div>{title}</div>
                    </h2>
                </div>

                {subtitle && (
                    <div
                        style={{
                            height: '20px',
                            overflow: 'hidden',
                            position: 'relative',
                            marginBottom: '2rem'
                        }}
                    >
                        <h3
                            className="aboutBbin-title-h3"
                            style={{
                                transform: contentStatus
                                    ? 'translate(0, 0)'
                                    : 'translate(0, 100%)'
                            }}
                        >
                            <div>{subtitle}</div>
                        </h3>
                    </div>
                )}

                <div
                    style={{
                        backgroundColor: '#787878',
                        height: '1px',
                        margin: '0 5rem 2rem'
                    }}
                >
                </div>

                <div style={{ height: '449px' }}>
                    {contentStatus && (
                        <div className="dialog-animation-contaner-img">
                            {type === 'image' ? (
                                <img
                                    className="aboutBbin-img"
                                    src={src}
                                    alt={title}
                                />
                            ) : (
                                <iframe
                                    className="youtube-video"
                                    width="600"
                                    height="400"
                                    src={`${src}?enablejsapi=1`}
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                    style={{ paddingBottom: '2rem' }}
                                >
                                </iframe>
                            )}
                        </div>
                    )}
                </div>

                <div
                    style={{
                        overflow: 'hidden'
                    }}
                >
                    <p
                        className="aboutBbin-p"
                        style={{
                            transform: contentStatus
                                ? 'translate(0, 0)'
                                : 'translate(0, 100%)'
                        }}
                    >
                        {children}
                    </p>
                </div>
            </div>
        </DetailDialog>
    );
};

export default memo(HallwayVideoDialog);
