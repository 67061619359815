import {memo, useEffect} from 'react';
import Bottoms from 'components/models/Bottoms/Bottoms';
import {useMainContext} from 'hooks/useMainContext';

const SceneEntrace = ({lobyToHallway, hallwayPositionX}: any) => {
    const {center} = useMainContext();

    useEffect(() => {
        lobyToHallway();
        center.hallwayPercentOrgine.x = hallwayPositionX;
        center.hallwayPercentTarget.x = hallwayPositionX;
    }, []);

    return (
        <>
            <Bottoms />
        </>
    );
};

export default memo(SceneEntrace);
