import { preventCanvasBubbling } from '../../../utils/preventCanvasBubbling';
import { MeshPhysicalMaterialProps } from '@react-three/fiber';
import { memo, useRef, useEffect } from 'react';
import { MeshDistortMaterial, MeshWobbleMaterial } from '@react-three/drei';

interface BasicModelMaterial extends MeshPhysicalMaterialProps {
    distort?: number;
    speed?: number;
    factor?: number;
}

interface BasicModelProps {
    item: any;
    maps: BasicModelMaterial;
    materialType?: 'basic' | 'distort' | 'wobble'; // 後面兩個是飄動材質
    layers?: number;
}

const BasicModel = ({
    item,
    maps,
    materialType = 'basic',
    layers = 0
}: BasicModelProps) => {
    const meshRef = useRef<any>();

    useEffect(() => {
        return () => {
            meshRef.current?.geometry.dispose();
            meshRef.current?.material.dispose();
        };
    }, []);
    return (
        <mesh
            ref={meshRef}
            {...item}
            castShadow
            receiveShadow
            onPointerEnter={preventCanvasBubbling}
            onClick={preventCanvasBubbling}
            layers={layers}
        >
            {materialType === 'basic' && (
                <meshPhysicalMaterial
                    {...item.material}
                    {...maps}
                />
            )}
            {materialType === 'distort' && (
                <MeshDistortMaterial
                    {...item.material}
                    {...maps}
                />
            )}
            {materialType === 'wobble' && (
                <MeshWobbleMaterial
                    {...item.material}
                    {...maps}
                />
            )}
        </mesh>
    );
};

export default memo(BasicModel);