// import { INFINITY_ITEM_DEPTH } from 'features/CamMovingCenter/cameraConfig';
import './hallwayOne.scss';
import BtmGround from 'components/models/BtmGround/BtmGround';
import HallwayPc from './HallwayPc/HallwayPc';
import { memo } from 'react';
const HallwayOne = ({ groupRef }: any) => {
    return (
        <group ref={groupRef as any}>
            <HallwayPc />
            <BtmGround position={[0, 0, 0]} />
        </group>
    );
};

export default memo(HallwayOne);
