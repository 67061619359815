import './sceneOneBtnMobile.scss';

const SceneOneBtnMobile = ({
    handleAboutBBINClick
}: {
    handleAboutBBINClick: () => void;
}) => {
    return (
        <button
            className="sceneOneBtns-group-mobile-btn"
            onClick={handleAboutBBINClick}
        >
            <p>ENTER</p>
        </button>
    );
};

export default SceneOneBtnMobile;
