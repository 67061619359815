// const loadingCount = useMemo(() => isMobile ? 46 : 49, [isMobile]);
// const progress = (( (loaded / loadingCount)) * 100).toFixed(0);


//version2
import { useState, useEffect } from 'react';

interface ProgressLoaderProps {
  countNum: boolean;
  setCountNum: React.Dispatch<React.SetStateAction<boolean>>;
  currentNumber: number | null;
  setCurrentNumber: React.Dispatch<React.SetStateAction<number | null>>;
  setCanvasStatus: (status: boolean) => void;
}

const ProgressLoader: React.FC<ProgressLoaderProps> = ({
  countNum,
  setCountNum,
  currentNumber,
  setCurrentNumber,
  setCanvasStatus
}) => {
  const [startTime, setStartTime] = useState<number>(0);

  useEffect(() => {
    if (countNum) {
      setStartTime(Date.now());
      let animationFrameId: number;
      
      const animate = () => {
        const currentTime = Date.now();
        const elapsed = currentTime - startTime;
        const duration = 2000; // 2秒
        
        if (elapsed < duration) {
          // 使用 easeOutQuad 緩動函數使動畫更自然
          const progress = elapsed / duration;
          const easeProgress = 1 - (1 - progress) * (1 - progress);
          const nextNumber = Math.min(Math.floor(easeProgress * 100), 100);
          
          setCurrentNumber(nextNumber);
          animationFrameId = requestAnimationFrame(animate);
        } else {
          setCurrentNumber(100);
          setCanvasStatus(true);
          // setCountNum(false); 不要這時候歸零
        }
      };

      animationFrameId = requestAnimationFrame(animate);

      return () => {
        if (animationFrameId) {
          cancelAnimationFrame(animationFrameId);
        }
      };
    }
  }, [countNum, setCurrentNumber, setCanvasStatus, setCountNum, startTime]);

  useEffect(() => {
    if (!countNum) {
      setCurrentNumber(0);
      setStartTime(0);
    }
  }, [countNum, setCurrentNumber]);

  return <>{`${currentNumber ?? 0}%`}</>;
};

export default ProgressLoader;
