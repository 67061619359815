import { Plane, MeshDistortMaterial } from '@react-three/drei';
import HallwayVideoDialog from '../HallwayVideoDialog/HallwayVideoDialog';
import { FrontSide } from 'three';
import { HallwayVideoPlaneProps } from '../HallwayVideoPlane/HallwayVideoPlane';
import { useRef, useState, memo } from 'react';
import { useMainContext } from 'hooks/useMainContext';
import { useFrame } from '@react-three/fiber';
import { SCENE_1_TARGET_Z } from 'features/CamMovingCenter/cameraConfig';

const HallwayOnePlane = ({
    dialogContent,
    children,
    args,
    position,
    rotation
}: HallwayVideoPlaneProps) => {
    const {
        title,
        subtitle,
        type,
        // textureType = 'image',
        src,
        texture
    } = dialogContent;

    const [dialogStatus, setDialogStatus] = useState(false);
    const [contentStatus, setContentStatus] = useState(false);
    const { center } = useMainContext();

    const ref = useRef<any>();

    const handleClick = () => {
        if (center.scrollPause || dialogStatus) return;
        if (
            title !== center.hallwayText.type ||
            center.hallwayText.opacity === 0
        )
            return;

        setDialogStatus(true);
        center.scrollPause = true;
        setTimeout(() => {
            setContentStatus(true);
        }, 1000);
    };

    const handlePointerMove = () => {
        if (
            title !== center.hallwayText.type ||
            center.hallwayText.opacity === 0 ||
            center.isMoving
        )
            return;
        document.body.className = 'pointer';
    };

    const handlePointerLeave = () => {
        if (
            title !== center.hallwayText.type ||
            center.hallwayText.opacity === 0
        )
            return;
        document.body.className = '';
    };

    useFrame(({ camera }) => {
        const planePositionZ = ((ref.current?.parent?.position.z as any) +
            ref.current?.position.z) as any;

        const opacityOffset = SCENE_1_TARGET_Z - planePositionZ;
        const opacityPercents = opacityOffset / 10.2;

        if (planePositionZ > -8 && planePositionZ < 0.5) {
            center.hallwayText = {
                type: title,
                opacity: 1,
                color: '#e1deec'
            };
        } else {
            if (center.hallwayText.type === title) {
                document.body.className = '';
                center.hallwayText = {
                    type: title,
                    opacity: 0,
                    color: '#e1deec'
                };
            }
        }

        if (planePositionZ > 0) {
            ref.current.material.opacity = opacityPercents;
        } else {
            ref.current.material.opacity = 1;
        }

        if (center.where === 'hallway' && center.isMoving) {
            setDialogStatus(false);
            setContentStatus(false);
        }
    });

    return (
        <>
            <Plane
                ref={ref as any}
                args={args as any}
                position={position as any}
                layers={1}
                rotation={rotation as any}
                onClick={handleClick}
                onPointerMove={handlePointerMove}
                onPointerLeave={handlePointerLeave}
            >
                <MeshDistortMaterial
                    map={texture as any}
                    side={FrontSide}
                    transparent={true}
                    speed={1.8}
                    distort={0.27}
                    specularIntensity={0}
                    envMapIntensity={-0.7}
                />
            </Plane>

            <HallwayVideoDialog
                title={title}
                subtitle={subtitle}
                src={src}
                type={type}
                dialogStatus={dialogStatus}
                setDialogStatus={setDialogStatus}
                contentStatus={contentStatus}
                setContentStatus={setContentStatus}
            >
                {children}
            </HallwayVideoDialog>
        </>
    );
};

export default memo(HallwayOnePlane);
