import { useMemo, useEffect } from 'react';
import { useTexture, useKTX2 } from '@react-three/drei';
import useLoadTexturePath from './useLoadTexturePath';
// import useLoadDesktopVideoTexture from './useLoadDesktopVideoTexture';
import { TextureGroup } from './types/TextureGroup';

const useLoadTexture = (): TextureGroup => {
    // const { isMobile } = useDetectGPU();
    const textureGroup = useLoadTexturePath();

    const [
        c1,
        c2,
        c3,
        aboutBBIN,
        igaTexture,
        egrTexture,
        agaTexture,
        europeGamingAwardsTexture,
        starletAwardsTexture,
        PiCEPhilippinesTexture,

        year2019Texture,
        year2020Texture,
        year2021Texture,
        year2022Texture,
        year2023Texture
    ] = useTexture([
        '/leading/leadingPageAssets/images/cloud/cloud01.png',
        '/leading/leadingPageAssets/images/cloud/cloud02.png',
        '/leading/leadingPageAssets/images/cloud/cloud03.png',

        '/leading/leadingPageAssets/images/hallway/AboutBBIN/About-BBIN-cover.jpg',
        '/leading/leadingPageAssets/images/hallway/IGA/IGA_1100x730.jpg',
        '/leading/leadingPageAssets/images/hallway/EGR/EGR_1100x730.jpg',
        '/leading/leadingPageAssets/images/hallway/AGA/AGA_1100x730.jpg',
        '/leading/leadingPageAssets/images/hallway/EuropeGamingAwards/Europe-Gaming-Awards_1100x730.jpg',
        '/leading/leadingPageAssets/images/hallway/StarletAwards/Starlet-Awards_1100x730.jpg',
        '/leading/leadingPageAssets/images/hallway/SPiCEPhilippines/SPiCE-Philippines_1100x730.jpg',

        '/leading/leadingPageAssets/images/hallway/year/2019.jpg',
        '/leading/leadingPageAssets/images/hallway/year/2020.jpg',
        '/leading/leadingPageAssets/images/hallway/year/2021.jpg',
        '/leading/leadingPageAssets/images/hallway/year/2022.jpg',
        '/leading/leadingPageAssets/images/hallway/year/2023/2023.jpg'
    ]);

    const [
        btmFloormap,
        btmFloornormalMap,
        btmFloorenvMap,

        btmWallmap,
        btmWallnormalMap,
        btmWallenvMap,

        bottomArtModelOnemap,
        bottomArtModelOnemetalnessMap,
        bottomArtModelOnenormalMap,
        bottomArtModelOneenvMap,

        bottomArtModelThreemap,
        bottomArtModelThreemetalnessMap,
        bottomArtModelThreenormalMap,

        bottomArtModelTwomap,
        bottomArtModelTwometalnessMap,
        bottomArtModelTwonormalMap
    ] = useKTX2(textureGroup);

    const defaultTextures = {
        c1,
        c2,
        c3,
        aboutBBIN,
        igaTexture,
        egrTexture,
        agaTexture,
        europeGamingAwardsTexture,
        starletAwardsTexture,
        PiCEPhilippinesTexture,

        year2019Texture,
        year2020Texture,
        year2021Texture,
        year2022Texture,
        year2023Texture,

        btmFloormap,
        btmFloornormalMap,
        btmFloorenvMap,

        btmWallmap,
        btmWallnormalMap,
        btmWallenvMap,

        bottomArtModelOnemap,
        bottomArtModelOnemetalnessMap,
        bottomArtModelOnenormalMap,
        bottomArtModelOneenvMap,

        bottomArtModelThreemap,
        bottomArtModelThreemetalnessMap,
        bottomArtModelThreenormalMap,

        bottomArtModelTwomap,
        bottomArtModelTwometalnessMap,
        bottomArtModelTwonormalMap
    };

    const res = useMemo(() => {
        return defaultTextures;
    }, []);


    // 如果手動釋放了紋理資源，而這些資源從緩存取得，那下次使用還需要重新加載
    // useEffect(() => {
    //     return () => {
    //         c1.dispose();
    //         c2.dispose();
    //         c3.dispose();

    //         aboutBBIN.dispose();
    //         igaTexture.dispose();
    //         egrTexture.dispose();
    //         agaTexture.dispose();
    //         europeGamingAwardsTexture.dispose();
    //         starletAwardsTexture.dispose();
    //         PiCEPhilippinesTexture.dispose();

    //         bottomArtModelOnemap.dispose();
    //         bottomArtModelOnemetalnessMap.dispose();
    //         bottomArtModelOnenormalMap.dispose();
    //         bottomArtModelOneenvMap.dispose();

    //         bottomArtModelThreemap.dispose();
    //         bottomArtModelThreemetalnessMap.dispose();
    //         bottomArtModelThreenormalMap.dispose();

    //         bottomArtModelTwomap.dispose();
    //         bottomArtModelTwometalnessMap.dispose();
    //         bottomArtModelTwonormalMap.dispose();

    //         btmFloormap.dispose();
    //         btmFloornormalMap.dispose();
    //         btmFloorenvMap.dispose();

    //         btmWallmap.dispose();
    //         btmWallnormalMap.dispose();
    //         btmWallenvMap.dispose();
    //     };
    // }, []);

    return res;
};

export default useLoadTexture;
