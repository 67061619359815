import { useTranslation } from 'react-i18next';

const TitleCarousel = ({ visibility, btnStatus, handleClickSection }: any) => {
    const { t } = useTranslation();

    return (
        <>
            <div
                className="titleCarousel"
                style={{
                    visibility: visibility ? 'visible' : 'hidden',
                    opacity: visibility ? '1' : '0'
                }}
            >
                <button
                    onClick={() => {
                        handleClickSection(0);
                    }}
                    className="titleCarousel-title"
                    style={{
                        border:
                            btnStatus === 0
                                ? '1px solid #000000'
                                : '1px solid #00000000'
                    }}
                >
                    {t('AboutBBIN')}
                </button>

                <div className="titleCarousel-divider" />

                <button
                    onClick={() => {
                        handleClickSection(1);
                    }}
                    className="titleCarousel-title"
                    style={{
                        border:
                            btnStatus === 1
                                ? '1px solid #000000'
                                : '1px solid #00000000'
                    }}
                >
                    {t('YearlyBrandVision')}
                </button>

                <div className="titleCarousel-divider" />

                <button
                    onClick={() => {
                        handleClickSection(2);
                    }}
                    className="titleCarousel-title"
                    style={{
                        border:
                            btnStatus === 2
                                ? '1px solid #000000'
                                : '1px solid #00000000'
                    }}
                >
                    {t('HonorsAndAwards')}
                </button>
            </div>
        </>
    );
};

export default TitleCarousel;
