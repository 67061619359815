import { Html } from '@react-three/drei';
import './mobileCarousel.scss';
import { useState, memo, useEffect } from 'react';
import { useDetectGPU } from '@react-three/drei';
import { useMainContext } from 'hooks/useMainContext';
import { useFrame } from '@react-three/fiber';
import MobileCarouselItem from './MobileCarouselItem/MobileCarouselItem';
import { useTranslation } from 'react-i18next';
import MobileCarouselText from './MobileCarouselText/MobileCarouselText';
import useMobileCarousel from './useMobileCarousel';
import MobileCarouselDialog from './MobileCarouselDialog/MobileCarouselDialog';
import TitleCarousel from './TitleCarousel/TitleCarousel';
import MobileCarouselBtnGroup from './MobileCarouselBtnGroup/MobileCarouselBtnGroup';

const time = 1200;

const MobileCarousel = () => {
    const { ARR, ARR_TWO, ARR_THREE } = useMobileCarousel();
    const { i18n } = useTranslation();

    const { center } = useMainContext();
    const { isMobile } = useDetectGPU();
    const [carouselStatus, setCarouselStatus] = useState(0);
    const [btnStatus, setBtnStatus] = useState(0);
    const [visibility, setVisibility] = useState(false);
    const [carouselVisibility, setCarouselVisibility] = useState(true);
    const [hallwaySection, setHallwaySection] = useState(0);
    const [carouselList, setCarouselList] = useState(ARR);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [contentStatus, setContentStatus] = useState(false);
    const [dialogData, setDialogData] = useState({ arr: ARR, index: 0 });

    const handleDialogClose = () => {
        setContentStatus(false);
        setDialogOpen(false);

        setTimeout(() => {
            const dialogs: any = document.querySelectorAll(
                '.detailDialog-scrollbar'
            );
            dialogs.forEach((item: any) => {
                item.scrollTo({ top: 0 });
            });
        }, 500);
    };

    const handleDialogOpen = (arr: any) => (e: any) => {
        setContentStatus(false);
        setDialogOpen(false);

        const target = document.getElementsByClassName(
            'mobile-detailDialog'
        )[0];
        target.scrollTo({ top: 0 });

        setDialogData({ arr: arr, index: e });
        setDialogOpen(true);

        setTimeout(() => {
            setContentStatus(true);
        }, 500);
    };

    useFrame(() => {
        if (center.where === 'hallway' && !center.isMoving) {
            if (visibility) return;
            setVisibility(true);
        } else {
            if (!visibility) return;
            setVisibility(false);
        }
    });

    const handleClickSection = (status: number) => {
        if (!carouselVisibility || hallwaySection === status) return;
        center.hallwayPercentTarget.x += 0.2;
        center.hallwayfogStatus = status;

        setBtnStatus(status);
        setCarouselVisibility(false);
        setCarouselStatus(0);

        setTimeout(() => {
            setHallwaySection(status);
            setCarouselList(() => {
                if (status === 0) return ARR;
                if (status === 1) return ARR_TWO;
                if (status === 2) return ARR_THREE;
                return [];
            });
        }, time / 2);

        setTimeout(() => {
            setCarouselVisibility(true);
        }, time);
    };

    useEffect(() => {
        setCarouselList([]);
        setCarouselList(ARR);
        handleClickSection(0);
    }, [i18n.language]);

    if (!isMobile) return <></>;
    return (
        <Html wrapperClass="ui_Btn">
            <div
                className="mobileCarousel-container"
                style={{ height: window.innerHeight }}
            >
                <div className="mobileCarousel-text">
                    <MobileCarouselText
                        visibility={visibility && carouselVisibility}
                        hallwaySection={hallwaySection}
                    />
                </div>

                <div
                    className="mobileCarousel"
                    style={{
                        visibility:
                            visibility && carouselVisibility
                                ? 'visible'
                                : 'hidden',
                        opacity: visibility && carouselVisibility ? '1' : '0'
                    }}
                >
                    <MobileCarouselBtnGroup
                        carouselList={carouselList}
                        carouselStatus={carouselStatus}
                        setCarouselStatus={setCarouselStatus}
                    />

                    {carouselList.map((item: any, index: number, arr: any) => {
                        return (
                            <MobileCarouselItem
                                key={index}
                                item={item}
                                index={index}
                                hallwaySection={hallwaySection}
                                carouselStatus={carouselStatus}
                                handleDialogOpen={handleDialogOpen(arr)}
                            />
                        );
                    })}
                </div>
                <TitleCarousel
                    visibility={visibility}
                    btnStatus={btnStatus}
                    handleClickSection={handleClickSection}
                />
            </div>

            <MobileCarouselDialog
                dialogData={dialogData}
                dialogOpen={dialogOpen}
                contentStatus={contentStatus}
                handleDialogClose={handleDialogClose}
                hallwaySection={hallwaySection}
                handleDialogOpen={handleDialogOpen}
            />
        </Html>
    );
};

export default memo(MobileCarousel);
