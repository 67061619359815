import { useRef } from 'react';
import { createPortal } from 'react-dom';
import './screenOrientation.scss';
import { useDetectGPU } from '@react-three/drei';

// 出現的判斷在scss中
const ScreenOrientation = () => {
    const screenOrientationRef = useRef<any>();
    const { isMobile } = useDetectGPU();

    if (!isMobile) {
        return <></>;
    }
    return createPortal(
        <div
            className="screenOrientation"
            ref={screenOrientationRef}
        >
            <div>
                <p>Please rotate your device</p>
                <img
                    src="/leading/leadingPageAssets/images/orientation/orientation.gif"
                    alt="orientation"
                />
            </div>
        </div>,
        document.body
    );
};

export default ScreenOrientation;
