import { useThree } from '@react-three/fiber';
import { CamMovingCenter } from '../CamMovingCenter';
import {
    CAMERA_ROTATION_MOVEMENT_X,
    CAMERA_ROTATION_MOVEMENT_Y
} from '../cameraConfig';
import { useDetectGPU } from '@react-three/drei';
import useLoadTexture from './useLoadTexture';

const useCamMoveCenter = () => {
    const { isMobile } = useDetectGPU();
    const textures = useLoadTexture();

    const camera = useThree((state) => state.camera);
    const center = new CamMovingCenter(camera, isMobile);

    // center.handleCameraRotation({ x: 0, y: 0, z: 0 })
    const handleMouseMove = (e: MouseEvent) => {
        if (isMobile) {
            return;
        }
        // const x = e.movementX / window.innerWidth;
        // const y = e.movementY / window.innerHeight;

        let cameraRotationProxyX: any = 0;
        let cameraRotationProxyY: any = 0;

        cameraRotationProxyX =
            (e.clientY / window.innerHeight) * CAMERA_ROTATION_MOVEMENT_X;
        cameraRotationProxyY =
            (e.clientX / window.innerWidth) * CAMERA_ROTATION_MOVEMENT_Y;

        center.cameraRotationOffset.x = cameraRotationProxyX;
        center.cameraRotationOffset.y = cameraRotationProxyY;
    };

    return { center, handleMouseMove, textures };
};

export default useCamMoveCenter;
