import axios from 'axios';

const subjectMap = {
    'Game API': '1',
    'White Label Solution': '2',
    '娱乐平台API介接': '1',
    '自有品牌建构': '2',
} as {
    [key: string]: string;
};

export const handleSubmit = async (
    lang: String,
    data: any,
    countryData: any,
    handleReset: Function,
    handleGetCaptcha: Function,
    setSubmitLock: Function
) => {
    const {
        content,
        name,
        country,
        tel,
        email,
        msg_id,
        subject,
        captcha
    } = data;

    const countryInfo = countryData.filter((item: any) => {
        return (item.name === country);
    })[0];
    const subjectID = subject ? subjectMap[subject] : '3';

    const formData = new FormData();
    formData.append('Contact[name]', name);
    formData.append('Contact[tel]', tel);
    formData.append('Contact[email]', email);
    formData.append('Contact[country]', countryInfo.id);
    formData.append('Contact[msg_id]', msg_id);
    formData.append('Contact[subject]', subjectID);
    formData.append('Contact[content]', content);
    formData.append('captcha', captcha);

    axios({
        url: `/bbin/${lang}/Contact/submit`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: formData
    })
        .then((res) => {
            console.log(res.data);
            window.alert(res.data.message);
            if (res.data._type === 'success') handleReset();
            handleGetCaptcha();
            setSubmitLock(false);
        })
        .catch((err) => {
            console.log(err.data);
        });
};
