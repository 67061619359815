import { useTranslation } from 'react-i18next';
import useHallwayOne from '../useHallwayOne';
import { useDetectGPU } from '@react-three/drei';
import { memo } from 'react';
import { useMainContext } from 'hooks/useMainContext';
import CloudSmoke from 'components/models/CloudSmoke/CloudSmoke';
import HallwayOnePlane from 'components/models/HallwayOnePlane/HallwayOnePlane';


const HallwayPc = () => {
    const { textures } = useMainContext();
    const { aboutBBIN } = textures;
    const { t } = useTranslation();
    const { ARR_ONE, ARR_TWO } = useHallwayOne();
    const { isMobile } = useDetectGPU();

    if (isMobile) return <></>;
    return (
        <>
            {/* DEV */}
            {/* <Plane
                args={[10, 10]}
                position={[30, -81, -30]}
                layers={1}
            >
                <meshBasicMaterial
                    color={'#d349f3'}
                    side={THREE.DoubleSide}
                />
            </Plane> */}

            {ARR_ONE.map((item, index) => {
                return (
                    <CloudSmoke
                        key={index}
                        {...item}
                    />
                );
            })}

            <group position={[0, -0.2, 0]}>
                {ARR_TWO.map((item, index) => {
                    return (
                        <CloudSmoke
                            key={index}
                            {...item}
                        />
                    );
                })}
            </group>

            
            <HallwayOnePlane
                args={[6, 3.3]}
                position={[0, -81, -70]}
                dialogContent={{
                    title: 'BBIN',
                    subtitle: t('about-bbin-subtitle'),
                    src: '/leading/leadingPageAssets/images/hallway/AboutBBIN/About BBIN_1100x730.png',
                    type: 'image',
                    texture: aboutBBIN,
                    textOneXOffset: 0,
                    textTwoXOffset: 0
                }}
            >
                {t('about-bbin-content-1')}
                <br />
                {t('about-bbin-content-2')}
            </HallwayOnePlane>

            
            
        </>
    );
};

export default memo(HallwayPc);
