import { makeCloudSmokeObj } from 'components/models/CloudSmoke/makeCloudSmokeObj';
import { INFINITY_ITEM_DEPTH } from 'features/CamMovingCenter/cameraConfig';
import { useMemo } from 'react';
const useHallwayThree = () => {
    const ARR3D = useMemo(
        () => [
            makeCloudSmokeObj([1, -82.5, -90.3], 0.2, 0.3),
            makeCloudSmokeObj([2, -81.8, -90.2], 0.3, 0.5),
            makeCloudSmokeObj([3.5, -82.5, -91.4], 0.2, 0.3),
            makeCloudSmokeObj([4.4, -82.6, -91.5], 0.1, 0.2)
        ],
        []
    );

    const ARRLAST = useMemo(
        () => [
            makeCloudSmokeObj([4.7, -82.5, -240.3], 0.3, 0.5),
            makeCloudSmokeObj([6.8, -82, -240.2], 0.35, 0.5),
            makeCloudSmokeObj([-4.5, -83, -240.4], 0.3, 0.2),
            makeCloudSmokeObj([-6, -82.5, -239.5], 0.35, 0.4)
        ],
        []
    );

    const ARREGR = useMemo(
        () => [
            makeCloudSmokeObj([1, -82.5, -126.3], 0.1, 0.2),
            makeCloudSmokeObj([2, -82.5, -126.2], 0.2, 0.4),
            makeCloudSmokeObj([3.2, -82.5, -126.4], 0.15, 0.2)
        ],
        []
    );

    const ARRSTAR = useMemo(
        () => [
            makeCloudSmokeObj([-2, -82.5, -191.3], 0.2, 0.2),
            makeCloudSmokeObj([-1, -82.5, -191.2], 0.3, 0.5),
            makeCloudSmokeObj([0, -82.5, -191.4], 0.2, 0.2),
            makeCloudSmokeObj([-4.5, -83, -189], 0.3, 0.1),
            makeCloudSmokeObj([-5.7, -82, -189.1], 0.3, 0.3)
        ],
        []
    );

    const ARR_BOTTOM_GROUND = useMemo(
        () => [
            [0, 0, 0],
            [0, 0, -INFINITY_ITEM_DEPTH],
            [0, 0, -2 * INFINITY_ITEM_DEPTH],
            [0, 0, -3 * INFINITY_ITEM_DEPTH],
            [0, 0, -4 * INFINITY_ITEM_DEPTH]
        ],
        []
    );

    return { ARR3D, ARRLAST, ARREGR, ARRSTAR, ARR_BOTTOM_GROUND };
};

export default useHallwayThree;
