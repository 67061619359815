import { makeCloudSmokeObj } from 'components/models/CloudSmoke/makeCloudSmokeObj';
import { useMemo } from 'react';

const useHallwayOne = () => {
    const ARR_ONE = useMemo(
        () => [
            makeCloudSmokeObj([3, -82.5, -70.1], 0.3, 0.5),
            makeCloudSmokeObj([4.5, -81.8, -70.2], 0.2, 0.2)
        ],
        []
    );
    const ARR_TWO = useMemo(
        () => [
            makeCloudSmokeObj([-3, -82.5, -69.1], 0.3, 0.5),
            makeCloudSmokeObj([-4.7, -81.5, -69.2], 0.2, 0.2)
        ],
        []
    );

    return { ARR_ONE, ARR_TWO };
};

export default useHallwayOne;
