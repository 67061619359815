import { createPortal } from 'react-dom';
import { ReactComponent as Cross } from '../../../../../assets/icons/X.svg';
import WestRoundedIcon from '@mui/icons-material/WestRounded';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import { useTranslation } from 'react-i18next';

const MobileCarouselDialog = ({
    dialogData,
    dialogOpen,
    contentStatus,
    handleDialogClose,
    hallwaySection,
    handleDialogOpen
}: any) => {
    const { arr, index } = dialogData;
    const { t } = useTranslation();
    const handleTitleColor = () => {
        const checkHallwayPart = hallwaySection;

        if (checkHallwayPart === 0) {
            return '#B92A24';
        }
        if (checkHallwayPart === 1) {
            return '#ef8e35';
        }
        if (checkHallwayPart === 2) {
            return '#4cd8c0';
        }
    };

    return (
        <>
            {createPortal(
                <>
                    <div
                        className="mobile-detailDialog"
                        style={{
                            // border: '2px solid white',
                            transform: dialogOpen ? 'scale(1)' : 'scale(0)',
                            opacity: dialogOpen ? '1' : '0'
                        }}
                    >
                        <button
                            className="cross-btn"
                            onClick={() => {
                                handleDialogClose();
                            }}
                        >
                            <Cross />
                        </button>
                        <div className="mobile-detailDialog-main">
                            <div
                                style={{
                                    // height: '40px',
                                    overflow: 'hidden',
                                    position: 'relative',
                                    marginBottom: '0.5rem'
                                }}
                            >
                                <h2
                                    style={{
                                        transform: contentStatus
                                            ? 'translate(0, 0)'
                                            : 'translate(0, 100%)',
                                        color: handleTitleColor()
                                    }}
                                    className="mobile-detailDialog-main-title"
                                >
                                    {arr[index].title}
                                </h2>
                            </div>

                            <div
                                style={{
                                    height: '24px',
                                    overflow: 'hidden',
                                    position: 'relative',
                                    marginBottom:
                                        hallwaySection === 2 ? '1rem' : '2rem',
                                    width: '100%'
                                }}
                            >
                                <h3
                                    className="mobile-detailDialog-main-title-h3"
                                    style={{
                                        fontSize:
                                            hallwaySection === 2
                                                ? '16px'
                                                : '24px',
                                        transform: contentStatus
                                            ? 'translate(0, 0)'
                                            : 'translate(0, 150%)'
                                    }}
                                >
                                    {arr[index].subtitle}
                                </h3>
                            </div>
                            <div
                                style={{
                                    width: window.innerWidth >= 576 ? '86%' : '100%',
                                    backgroundColor: '#787878',
                                    height: '1px',
                                    margin: '0 5rem 2rem'
                                }}
                            />

                            <div style={{ height: window.innerWidth >= 576 ? '316px' : '226px' }}>
                                {contentStatus && (
                                    <div className="mobile-detailDialog-img">
                                        {arr[index].type === 'image' && (
                                            <img
                                                className="main-img"
                                                src={arr[index].src}
                                                alt={arr[index].title}
                                            />
                                        )}
                                        {arr[index].type === 'youtube' && (
                                            <iframe
                                                className="main-img youtube"
                                                width={window.innerWidth >= 576 ? '400px' : '300px'}
                                                height={window.innerWidth >= 576 ? '300px' : '210px'}
                                                src={`${arr[index].insideSrc}?enablejsapi=1&loop=1`}
                                                title="YouTube video player"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                allowFullScreen
                                            />
                                        )}
                                        {arr[index].type === 'video' && (
                                            <div className="main-img">
                                                <video
                                                    src={arr[index].insideSrc}
                                                    loop
                                                    autoPlay
                                                    muted
                                                    playsInline
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>

                            <div
                                style={{
                                    overflow: 'hidden'
                                }}
                            >
                                <p
                                    className="main-p"
                                    style={{
                                        transform: contentStatus
                                            ? 'translate(0, 0)'
                                            : 'translate(0, 100%)'
                                    }}
                                >
                                    {arr[index].content}
                                </p>
                            </div>
                            <div className="mobile-detailDialog-btnGroup">
                                {arr.length !== 1 && (
                                    <>
                                        <button
                                            onClick={() => {
                                                if (index === 0) {
                                                    handleDialogOpen(arr)(
                                                        arr.length - 1
                                                    );
                                                    return;
                                                }
                                                handleDialogOpen(arr)(
                                                    index - 1
                                                );
                                            }}
                                        >
                                            <div className="btn-icon">
                                                <WestRoundedIcon />
                                            </div>
                                            <div>{t('PREVIOUS')}</div>
                                        </button>
                                        <button
                                            onClick={() => {
                                                if (index === arr.length - 1) {
                                                    handleDialogOpen(arr)(0);
                                                    return;
                                                }
                                                handleDialogOpen(arr)(
                                                    index + 1
                                                );
                                            }}
                                        >
                                            <div>{t('NEXT')}</div>
                                            <div className="btn-icon">
                                                <EastRoundedIcon />
                                            </div>
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    {dialogOpen && (
                        <div
                            className="backdrop"
                            style={{
                                backgroundColor: '#000000',
                                opacity: '0.5',
                                transition: '0.5s',
                                visibility: 'visible'
                            }}
                            onClick={() => {
                                handleDialogClose();
                            }}
                        />
                    )}
                </>,
                document.body
            )}
        </>
    );
};

export default MobileCarouselDialog;
