export const handleAllYtVideoClose = () => {
    const allYt: any = document.getElementsByClassName('youtube-video');

    if (allYt[0]) {
        allYt[0].contentWindow.postMessage(
            '{"event":"command","func":"stopVideo","args":""}',
            '*'
        );
    }
    // allYt[1].contentWindow.postMessage(
    //     '{"event":"command","func":"stopVideo","args":""}',
    //     '*'
    // );
    // allYt[2].contentWindow.postMessage(
    //     '{"event":"command","func":"stopVideo","args":""}',
    //     '*'
    // );
};
