import { CamMovingCenter } from '../CamMovingCenter';
import useLobyToHallway from '../animation/useLobyToHallway';
import useHallwayToLoby from '../animation/useHallwayToLoby';

const useMoveGroup = (center: CamMovingCenter) => {
    const lobyToHallway = useLobyToHallway(center);
    const hallwayToLoby = useHallwayToLoby(center);

    return {
        lobyToHallway,
        hallwayToLoby
    };
};

export default useMoveGroup;
