import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as Go } from '../../../../assets/icons/icon-01.svg';
import { ReactComponent as Contact } from '../../../../assets/icons/icon-02.svg';
import { ReactComponent as Bb } from '../../../../assets/icons/icon-03.svg';
import { ReactComponent as Global2 } from '../../../../assets/icons/icon-04.svg';

const useTopRightGroup = (
    setCanCancel: any,
    setContactDialog: any,
    hallwayToLoby: any
) => {
    const [lang, setLang] = useState('cn');
    const [rightTopDialog, setRightTopDialog] = useState(false);
    const { t, i18n } = useTranslation();
    const handleChangeLang = (lng: string) => {
        i18n.changeLanguage(lng);
        setLang(lng);
    };

    const mobileDialogItemArr = [
        {
            icon: <Go />,
            content: t('header-index'),
            function: () => {
                hallwayToLoby();
            }
        },
        {
            icon: <Contact />,
            content: t('header-contact'),
            function: () => {
                setContactDialog(true);
                setTimeout(() => {
                    setCanCancel(true);
                }, 1000);
            }
        },
        {
            icon: <Bb />,
            content: t('header-news'),
            function: () => {
                window.location.href = `/bbin/${lang}/News`;
            }
        },
        {
            icon: <Global2 />,
            content: 'CN / EN',
            function: () => {
                lang === 'cn' && handleChangeLang('en');
                lang === 'en' && handleChangeLang('cn');
            }
        }
    ];

    return {
        mobileDialogItemArr,
        handleChangeLang,
        lang,
        rightTopDialog,
        setRightTopDialog
    };
};

export default useTopRightGroup;
