import {
    INFINITY_GROUP_DEPTH,
    INFINITY_START_POSITION,
    INFINITY_ITEM_DEPTH
} from '../cameraConfig';

export const makeBuildingCircleLoop = (percentage: number, end: number) => {
    const groupsDepthTotal = 9 * INFINITY_ITEM_DEPTH + INFINITY_ITEM_DEPTH; // 第一組和最後一組的距離差

    const scrollPercent = percentage; // 滾軸位置百分比
    const scrollPercentMinus = 1 - (scrollPercent - Math.floor(scrollPercent)); // 滾軸位置百分比扣除整數
    const totalPercent = groupsDepthTotal * scrollPercentMinus; // 總長 乘以 百分比
    const differenceWithEnd = // 起點跟終點的差(終點)
        end -
        (INFINITY_START_POSITION -
            INFINITY_GROUP_DEPTH -
            INFINITY_ITEM_DEPTH -
            41);

    return totalPercent <= differenceWithEnd
        ? end - groupsDepthTotal * scrollPercentMinus
        : end + groupsDepthTotal * (1 - scrollPercentMinus);
};
