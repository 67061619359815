import { useFrame, useThree } from '@react-three/fiber';
import useMouseScroll from '../features/CamMovingCenter/hook/useMouseScroll';
import { useMainContext } from './useMainContext';
import { useEffect } from 'react';
import { turnToDegrees } from 'utils/turnToDegrees';
import {
    CAMERA_ROTATION_MOVEMENT_LERP,
    SCENE_1_INITIAL_Z
} from 'features/CamMovingCenter/cameraConfig';
import { Color, Fog, Vector3 } from 'three';
import { useDetectGPU } from '@react-three/drei';

const useCam = () => {
    const { isMobile } = useDetectGPU();
    const camera = useThree((state) => state.camera);
    const raycaster = useThree((state) => state.raycaster);
    const { center, handleMouseMove } = useMainContext();
    // 滾動
    useMouseScroll(center);

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove);
        camera.layers.enable(2);
        raycaster.layers.enable(0);
        raycaster.layers.enable(1);
        raycaster.layers.enable(2);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    useFrame(() => {
        if (center.where !== 'start') return;
        if (
            center.cameraPosition.z === SCENE_1_INITIAL_Z ||
            center.cameraPosition.z === 15 //在手機時為15
        ) {
            center.scrollPause = false;
            center.where = 'loby';
            center.handleMoving(false);
        }
    });

    const fogColor1 = new Color('rgba(100%,100%,100%)');
    const fogColor2 = new Color('rgba(100%,100%,100%)');
    const handleFog = () => {
        const checkHallwayPart = center.hallwayfogStatus;

        if (checkHallwayPart === 0) {
            fogColor2.set('#e1deec');
        }
        if (checkHallwayPart === 1) {
            fogColor2.set('#f9ddc0');
        }
        if (checkHallwayPart === 2) {
            fogColor2.set('#deeceb');
        }

        return new Fog(fogColor1.lerp(fogColor2, 0.025), 12, 40);
    };

    const cameraRotationVector3 = isMobile
        ? new Vector3(turnToDegrees(5), 0, 0)
        : new Vector3();
    const cameraRotationVector3Movement = isMobile
        ? new Vector3(turnToDegrees(5), 0, 0)
        : new Vector3();

    useFrame(({ camera, scene }) => {
        center.where === 'loby' && 
            !isMobile && 
            center.cameraPosition.lerp(center.cameraPositionSmooth, 0.07);


        const sceneOneToTwoBtn: any = document.getElementsByClassName(
            'sceneOne-to-sceneTwo'
        )[0];
        if (sceneOneToTwoBtn) {
            if (center.where === 'loby') {
                sceneOneToTwoBtn.style.opacity = 1;
            } else {
                sceneOneToTwoBtn.style.opacity = 0;
            }
        }

        camera.position.set(
            center.cameraPosition.x,
            center.cameraPosition.y,
            center.cameraPosition.z
        );

        cameraRotationVector3Movement.set(
            turnToDegrees(
                center.defaultCameraRotation.x - center.cameraRotationOffset.x
            ),
            isMobile
                ? 0
                : turnToDegrees(
                    center.defaultCameraRotation.y -
                          center.cameraRotationOffset.y
                ),
            0
        );
        cameraRotationVector3.lerp(
            cameraRotationVector3Movement,
            CAMERA_ROTATION_MOVEMENT_LERP
        );

        camera.rotation.x = cameraRotationVector3.x;
        camera.rotation.y = cameraRotationVector3.y;

        scene.fog = center.isFogOpen ? handleFog() : null;
    });

    return { camera };
};

export default useCam;
