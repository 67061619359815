import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import './selectInput.scss';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const SelectInput = ({ label, list, id, required = false }: any) => {
    const { t } = useTranslation();
    const [listStatus, setListStatus] = useState(false);
    const {
        register,
        setValue,
        formState: { errors },
        clearErrors
    } = useFormContext();

    return (
        <>
            <div
                className="inputText select-input"
                onClick={() => {
                    if (listStatus) return;
                    setListStatus(true);
                }}
                style={{ cursor: 'pointer' }}
            >
                <input
                    type="text"
                    readOnly
                    className={errors[id] ? 'error' : ''}
                    placeholder={errors[id] ? t('isRequired') : label}
                    autoComplete="off"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        clearErrors(id);
                    }}
                    {...register(id, { required: required })}
                />

                <ExpandMoreRoundedIcon sx={{ fontSize: 16 }} />

                <div
                    className="inputText-list"
                    style={
                        listStatus
                            ? {
                                // display: 'block',
                                opacity: 1,
                                transform: 'translateY(0px)',
                                visibility: 'visible'
                            }
                            : {
                                opacity: 0,
                                transform: 'translateY(-50px)',
                                visibility: 'hidden'
                            }
                    }
                >
                    <div
                        style={{
                            zIndex: '1005',
                            overflow: 'auto',
                            maxHeight: '200px'
                        }}
                    >
                        {/* <div
                            onClick={() => {
                                setListStatus(() => {
                                    return false;
                                });
                                setValue(id, '');
                            }}
                            className="inputText-list-item"
                        >
                            {'None'}
                        </div> */}
                        {list.map((item: any, index: number) => {
                            return (
                                <div
                                    onClick={() => {
                                        setListStatus(() => {
                                            return false;
                                        });
                                        setValue(id, item.value);
                                    }}
                                    className="inputText-list-item"
                                    key={index}
                                >
                                    {item.name}
                                    <div
                                        style={
                                            item.code
                                                ? {float: 'right'}
                                                : {display: 'none'}
                                        }
                                    >
                                        [{item.code}]
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            {listStatus && (
                <div
                    className="backdrop"
                    style={{ zIndex: '1004' }}
                    onClick={() => {
                        setListStatus(false);
                    }}
                />
            )}
        </>
    );
};

export default SelectInput;
