import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
const useMobileCarousel = () => {
    const { t, i18n } = useTranslation();
    const ARR = useMemo(
        () => [
            {
                type: 'image',
                src: '/leading/leadingPageAssets/images/hallway/AboutBBIN/AboutBBIN_Mobile.jpg',

                title: 'BBIN',
                subtitle: t('about-bbin-subtitle'),

                insideSrc:
                    '/leading/leadingPageAssets/images/hallway/AboutBBIN/About BBIN_1100x730.png',
                content: (
                    <>
                        {t('about-bbin-content-1')}
                        <br />
                        {t('about-bbin-content-2')}
                    </>
                )
            }
        ],
        [i18n.language, t]
    );
    const ARR_TWO = useMemo(
        () => [
            {
                type: 'youtube',
                src: '/leading/leadingPageAssets/images/hallway/year/2023/2023.jpg',

                title: '2023',
                subtitle: 'GO BETTER',

                insideSrc: i18n.language === 'en' ? 'https://www.youtube.com/embed/R81C3EeSl6M' : 'https://www.youtube.com/embed/8xF326FpQJg',
                content: <>{t('year-2023')}</>
            },
            {
                type: 'video',
                src: '/leading/leadingPageAssets/images/hallway/year/2022.jpg',

                title: '2022',
                subtitle: 'THE GAMING BEAT',

                insideSrc:
                    '/leading/leadingPageAssets/videos/texture_the_gaming_beat.mp4',
                content: <>{t('year-2022')}</>
            },
            {
                type: 'youtube',
                src: '/leading/leadingPageAssets/images/hallway/year/2021.jpg',

                title: '2021',
                subtitle: 'MOVE BEYOND DRIVE CHANGE',

                insideSrc: 'https://www.youtube.com/embed/c4YSUAxmvYk',
                content: <>{t('year-2021')}</>
            },
            {
                type: 'youtube',
                src: '/leading/leadingPageAssets/images/hallway/year/2020.jpg',

                title: '2020',
                subtitle: 'UP',

                insideSrc: 'https://www.youtube.com/embed/bGNzurCbrNM',
                content: <>{t('year-2020')}</>
            },
            {
                type: 'youtube',
                src: '/leading/leadingPageAssets/images/hallway/year/2019.jpg',

                title: '2019',
                subtitle: 'JUMP / 20 YEARS',

                insideSrc: 'https://www.youtube.com/embed/qkgnM8OBAUw',
                content: <>{t('year-2019')}</>
            }
        ],
        [i18n.language, t]
    );
    const ARR_THREE = useMemo(
        () => [
            {
                type: 'image',
                src: '/leading/leadingPageAssets/images/hallway/IGA/IGA_1100x730.jpg',

                title: 'IGA',
                subtitle: t('award-IGA-subtitle'),

                insideSrc: '/leading/leadingPageAssets/images/hallway/IGA/IGA_1100x730.png',
                content: (
                    <>
                        <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                            {t('award-bbin')}
                        </span>
                        <br />
                        <br />
                        2023
                        <br />
                        {t('award-IGA-1')}
                        <br />
                        {t('award-IGA-2')}
                        <br />
                        {t('award-IGA-3')}
                        <br />
                        {t('award-IGA-4')}
                        <br />
                        {t('award-IGA-5')}
                        <br />
                        {t('award-IGA-6')}
                        <br />
                        <br />
                        {t('award-IGA-7')}
                        <br />
                        {t('award-IGA-8')}
                        <br />
                        {t('award-IGA-9')}
                        <br />
                        {t('award-IGA-10')}
                        <br />
                        {t('award-IGA-11')}
                        <br />
                        <br />
                        2017
                        <br />
                        {t('award-IGA-12')}
                        <br />
                        {t('award-IGA-13')}
                        <br />
                        {t('award-IGA-14')}
                    </>
                )
            },
            {
                type: 'image',
                src: '/leading/leadingPageAssets/images/hallway/EGR/EGR_1100x730.jpg',

                title: 'EGR B2B',
                subtitle: t('award-EGR-subtitle'),

                insideSrc: '/leading/leadingPageAssets/images/hallway/EGR/EGR_1100x730.png',
                content: (
                    <>
                        <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                            {t('award-bbin')}
                        </span>
                        <br />
                        <br />
                        2023
                        <br />
                        {t('award-EGR-1')}
                        <br />
                        {t('award-EGR-2')}
                        <br />
                        <br />
                        2022
                        <br />
                        {t('award-EGR-3')}
                        <br />
                        {t('award-EGR-4')}
                        <br />
                        <br />
                        2021
                        <br />
                        {t('award-EGR-5')}
                        <br />
                        {t('award-EGR-6')}
                        <br />
                        {t('award-EGR-7')}
                        <br />
                        <br />
                        2020
                        <br />
                        {t('award-EGR-8')}
                        <br />
                        {t('award-EGR-9')}
                        <br />
                        <br />
                        2019
                        <br />
                        {t('award-EGR-10')}
                        <br />
                        {t('award-EGR-11')}
                        <br />
                        <br />
                        2018
                        <br />
                        {t('award-EGR-12')}
                        <br />
                        {t('award-EGR-13')}
                        <br />
                        {t('award-EGR-14')}
                        <br />
                        <br />
                        2017
                        <br />
                        {t('award-EGR-15')}
                    </>
                )
            },
            {
                type: 'image',
                src: '/leading/leadingPageAssets/images/hallway/AGA/AGA_1100x730.jpg',

                title: 'AGA',
                subtitle: t('award-AGA-subtitle'),

                insideSrc: '/leading/leadingPageAssets/images/hallway/AGA/AGA_1100x730.png',
                content: (
                    <>
                        <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                            {t('award-bbin')}
                        </span>
                        <br />
                        <br />
                        2023
                        <br />
                        {t('award-AGA-1')}
                        <br />
                        {t('award-AGA-2')}
                        <br />
                        {t('award-AGA-3')}
                        <br />
                        {t('award-AGA-4')}
                        <br />
                        <br />
                        2017
                        <br />
                        {t('award-AGA-5')}
                        <br />
                        <br />
                        2016
                        <br />
                        {t('award-AGA-6')}
                    </>
                )
            },
            {
                type: 'image',
                src: '/leading/leadingPageAssets/images/hallway/EuropeGamingAwards/Europe-Gaming-Awards_1100x730.jpg',

                title: 'Europe Gaming Awards',
                subtitle: t('award-EGA-subtitle'),

                insideSrc:
                    '/leading/leadingPageAssets/images/hallway/EuropeGamingAwards/Europe Gaming Awards_1100x730.png',
                content: (
                    <>
                        <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                            {t('award-bbin')}
                        </span>
                        <br />
                        <br />
                        {t('award-EGA-1')}
                        <br />
                        {t('award-EGA-2')}
                    </>
                )
            },
            {
                type: 'image',
                src: '/leading/leadingPageAssets/images/hallway/StarletAwards/Starlet-Awards_1100x730.jpg',

                title: 'Starlet Awards',
                subtitle: t('award-Star-subtitle'),

                insideSrc:
                    '/leading/leadingPageAssets/images/hallway/StarletAwards/Starlet Awards_1100x730.png',
                content: (
                    <>
                        <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                            {t('award-bbin')}
                        </span>
                        <br />
                        <br />
                        {t('award-Star-1')}
                        <br />
                        {t('award-Star-2')}
                    </>
                )
            },
            {
                type: 'image',
                src: '/leading/leadingPageAssets/images/hallway/SPiCEPhilippines/SPiCE-Philippines_1100x730.jpg',
                title: 'SPiCE Philippines',
                subtitle: t('award-spice-subtitle'),

                insideSrc:
                    '/leading/leadingPageAssets/images/hallway/SPiCEPhilippines/SPiCE Philippines_1100x730.png',
                content: (
                    <>
                        <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                            {t('award-bbin')}
                        </span>
                        <br />
                        <br />
                        2022
                        <br />
                        {t('award-spice-1')}
                        <br />
                        {t('award-spice-2')}
                    </>
                )
            }
        ],
        [i18n.language, t]
    );

    return { ARR, ARR_TWO, ARR_THREE };
};

export default useMobileCarousel;
