import { memo, useEffect, createContext,} from 'react';
import useMoveGroup from '../../../features/CamMovingCenter/hook/useMoveGroup';
import useCamMoveCenter from '../../../features/CamMovingCenter/hook/useCamMoveCenter';
import '../../../styles/uiBtn.scss';
import SceneEntrace from '../SceneEntrace/SceneEntrace';
import CanvasSetting from '../CanvasSetting/CanvasSetting';


export const MainContext: any = createContext(null);

const CanvasContainer = ({
    hallwayPositionX,
    setCanvasStatus,
    setHallwayToLoby,
    setVideoStatus,
    setCountNum
}: any) => {
    const camMoveCenter = useCamMoveCenter();
    
    const { lobyToHallway, hallwayToLoby } = useMoveGroup(camMoveCenter.center);

    useEffect(() => {
        setHallwayToLoby(() => () => {
            // 記得還原 計數的數字
            setCountNum(false);

            hallwayToLoby(); //長廊去大廳

            setTimeout(() => {
                setCanvasStatus(false);
                setVideoStatus(true); //打開影片
            }, 3000);  //3秒是因為要等曝光值提高、鏡頭拉近的動態

        });

    });

    return (
        <MainContext.Provider value={{ ...camMoveCenter }}>
            <CanvasSetting />
            <SceneEntrace
                lobyToHallway={lobyToHallway}
                hallwayPositionX={hallwayPositionX}
            />
        </MainContext.Provider>
    );
};

export default memo(CanvasContainer);
