import { CamMovingCenter } from 'features/CamMovingCenter/CamMovingCenter';

export const handleGuideTextColor = (
    center: CamMovingCenter,
    setGuideColor: any
) => {
    const { type } = center.hallwayText;
    switch (type) {
        case 'BBIN':
            setGuideColor([1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
            break;
        case '2023':
            setGuideColor([0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
            break;
        case '2022':
            setGuideColor([0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
            break;
        case '2021':
            setGuideColor([0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0]);
            break;
        case '2020':
            setGuideColor([0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0]);
            break;
        case '2019':
            setGuideColor([0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0]);
            break;
        case 'IGA':
            setGuideColor([0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0]);
            break;
        case 'EGR B2B':
            setGuideColor([0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0]);
            break;
        case 'AGA':
            setGuideColor([0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0]);
            break;
        case 'Europe Gaming Awards':
            setGuideColor([0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0]);
            break;
        case 'Starlet Awards':
            setGuideColor([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0]);
            break;
        case 'SPiCE Philippines':
            setGuideColor([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1]);
            break;

        default:
            setGuideColor([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
            break;
    }
};
