import { INFINITY_ITEM_DEPTH } from 'features/CamMovingCenter/cameraConfig';
import { memo, Suspense, lazy } from 'react';
// import BtmGround from '../BtmGround/BtmGround';
const BtmGround = lazy(() => import('../BtmGround/BtmGround'));

const BtmGroundSet = () => {
    return (
        <>
        <Suspense>
            <BtmGround position={[0, 0, 0]} />
            <BtmGround position={[0, 0, -INFINITY_ITEM_DEPTH]} />
            <BtmGround position={[0, 0, -2 * INFINITY_ITEM_DEPTH]} />
            <BtmGround position={[0, 0, -3 * INFINITY_ITEM_DEPTH]} />
        </Suspense>
        </>
    );
};

export default memo(BtmGroundSet);
