const LogoComponent = ({
    canvasStatus,
    setCanvasStatus,
    hallwayToLoby,
    setHallwayToLoby
}: any) => {
    return (
        <img
            className="fixedButtonGroup-logo"
            src={'/leading/leadingPageAssets/indexPage/logo.png'}
            alt="logo"
            onClick={() => {
                if (hallwayToLoby) hallwayToLoby();
                //執行完再清空
                setHallwayToLoby(null);
            }}
        />
    );
};

export default LogoComponent;
