import { useTranslation } from 'react-i18next';

import { ReactComponent as Global } from '../../../assets/icons/global.svg';
import { ReactComponent as Bbin } from '../../../assets/icons/bbin.svg';
import { ReactComponent as Cross } from '../../../assets/icons/X.svg';
import { ReactComponent as Contact } from '../../../assets/icons/icon-02.svg';

import MenuIcon from '@mui/icons-material/Menu';
import useTopRightGroup from './useTopRightGroup/useTopRightGroup';

const TopRightGroup = ({
    canvasStatus,
    setContactDialog,
    setCanCancel,
    hallwayToLoby
}: any) => {
    const {
        mobileDialogItemArr,
        handleChangeLang,
        lang,
        rightTopDialog,
        setRightTopDialog
    } = useTopRightGroup(setCanCancel, setContactDialog, hallwayToLoby);
    const { t, i18n } = useTranslation();

    return (
        <>
            <div className="fixedButtonGroup-rightTop">
                <a
                    className="fixedButtonGroup-rightTop-bbin"
                    href={`/bbin/${i18n.language}/News`}
                >
                    <Bbin />
                    <p>{t('header-news')}</p>
                </a>

                <button
                    className="fixedButtonGroup-rightTop-contact"
                    onClick={() => {
                        setContactDialog(true);
                        // center.scrollPause = true;
                        setTimeout(() => {
                            setCanCancel(true);
                        }, 1000);
                    }}
                >
                    <Contact />
                    <p>{t('header-contact')}</p>
                </button>

                <div className="fixedButtonGroup-rightTop-language">
                    <Global />
                    <div>
                        <button
                            className={`fixedButtonGroup-rightTop-language-cn ${i18n.language === 'cn' ? 'selected' : ''}`}
                            onClick={() => {
                                handleChangeLang('cn');
                            }}
                        >
                            CN
                        </button>
                        <p style={{ padding: '0 8px' }}>{'/'}</p>
                        <button
                            className={`fixedButtonGroup-rightTop-language-en ${i18n.language === 'en' ? 'selected': ''}`}
                            onClick={() => {
                                handleChangeLang('en');
                            }}
                        >
                            EN
                        </button>
                    </div>
                </div>
            </div>

            <div
                className="fixedButtonGroup-rightTop-mobile"
                onClick={() => {
                    setRightTopDialog((_s) => {
                        return !_s;
                    });
                }}
            >
                {rightTopDialog ? <Cross /> : <MenuIcon />}
            </div>

            {rightTopDialog && (
                <>
                    <div
                        className="fixedButtonGroup-rightTop-mobile-dialog"
                        style={{pointerEvents: 'visible'}}
                    >
                        {mobileDialogItemArr.map((item, index) => {
                            if (index === 3) {
                                return (
                                    <div
                                        key={item.content}
                                        className="dialog-item"
                                        onClick={() => {
                                            item.function();
                                        }}
                                    >
                                        <div className="dialog-svg">
                                            {item.icon}
                                        </div>
                                        <div className="dialog-content">
                                            <span
                                                style={{
                                                    color:
                                                        lang === 'cn'
                                                            ? 'black'
                                                            : '#b6b5b5'
                                                }}
                                            >
                                                CN
                                            </span>
                                            <span>{'\n / \n'}</span>
                                            <span
                                                style={{
                                                    color:
                                                        lang === 'en'
                                                            ? 'black'
                                                            : '#b6b5b5'
                                                }}
                                            >
                                                EN
                                            </span>
                                        </div>
                                    </div>
                                );
                            }

                            return (
                                <div
                                    key={item.content}
                                    className="dialog-item"
                                    onClick={() => {
                                        item.function();
                                        setRightTopDialog((_s) => {
                                            return !_s;
                                        });
                                    }}
                                >
                                    <div className="dialog-svg">
                                        {item.icon}
                                    </div>
                                    <div className="dialog-content">
                                        {item.content}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div
                        style={{
                            backgroundColor: '#000000',
                            opacity: '0.5',
                            transition: '0.5s',
                            visibility: 'visible',
                            zIndex: 8888,
                            pointerEvents: 'visible'
                        }}
                        className="backdrop"
                        onClick={() => {
                            setRightTopDialog(false);
                        }}
                    />
                </>
            )}
        </>
    );
};

export default TopRightGroup;
