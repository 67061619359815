// 初始動畫參數
export const START_X = 0;
export const START_Y = 3;
export const START_Z = 60;

// 第一場景scroll 參數
export const SCENE_1_INITIAL_X = 0;
export const SCENE_1_INITIAL_Y = 3;
export const SCENE_1_INITIAL_Z = 52;

export const SCENE_1_TARGET_Y = 2;
export const SCENE_1_TARGET_Z = 10.2;

// 相機搖晃參數
export const CAMERA_ROTATION_MOVEMENT_LERP = 0.035; // 搖晃延遲參數 0~1
export const CAMERA_ROTATION_MOVEMENT_X = 1.5; // x軸搖晃距離
export const CAMERA_ROTATION_MOVEMENT_Y = 2.5; // y軸搖晃距離

// Scene2 無限滾動參數
/* 這套公式一次只能放三組group，一組group中可以有多個item */
// 需修改為可調動組數的公式
export const INFINITY_ITEM_DEPTH = 41; // 單一模型深度
export const INFINITY_ITEM_IN_GROUP_AMOUNT = 4; // 一個群組有多少item
export const INFINITY_START_POSITION = 65; // 初始位置
export const INFINITY_GROUP_DEPTH = // 一個群組的深度
    INFINITY_ITEM_IN_GROUP_AMOUNT * INFINITY_ITEM_DEPTH;

// 修改模型大小必須修改以下參數
// makeBuildingCircleLoop中的groupsDepthTotal總長度
// useMouseScrollAtBottom中的修改的那一組的長度，迷霧的切換點
