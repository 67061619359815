import { memo } from 'react';
const GuideTitle = ({ status, title, click }: any) => {
    return (
        <div
            className="guide_title"
            style={status ? { color: '#000' } : { color: '#fff' }}
            onClick={() => {
                click();
            }}
        >
            <div
                className="guide_ball"
                style={
                    status
                        ? { backgroundColor: '#000' }
                        : { backgroundColor: '#fff' }
                }
            >
            </div>
            <div>{title}</div>
        </div>
    );
};

export default memo(GuideTitle);
